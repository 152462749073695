// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import * as monaco from 'monaco-editor';
import * as utilities from './utilities'

/* Diagnostics */
enum DiagnosticCategory {
    Warning = 0,
    Error = 1,
    Suggestion = 2,
    Message = 3
}

interface Diagnostic extends DiagnosticRelatedInformation { // same as https://microsoft.github.io/monaco-editor/api/interfaces/monaco.languages.typescript.diagnostic.html
    /** May store more in future. For now, this will simply be `true` to indicate when a diagnostic is an unused-identifier diagnostic. */
    relatedInformation?: DiagnosticRelatedInformation[];
    reportsUnnecessary?: {};
    source?: string;
}
interface DiagnosticRelatedInformation { // same as https://microsoft.github.io/monaco-editor/api/interfaces/monaco.languages.typescript.diagnosticrelatedinformation.html
    category: 0 | 1 | 2 | 3; // Diagnostic category: warning = 0, error = 1, suggestion = 2, message = 3
    code: number;
    file: undefined; // TypeScriptWorker removes this to avoid serializing circular JSON structures
    length: number | undefined;
    messageText: string;
    start: number | undefined;
}

export class DiagnosticsAdapter {

    private _disposables: monaco.IDisposable[] = [];
    private _listener = Object.create(null);

    private formulaStyle;
    private enabled;
    private analysisResult;

    setPreferences(preferences) {
        this.preferences = preferences;
    }
    constructor(private _selector: string, formulaStyle: string, private preferences = {}, setParsable, getEnabled) {

        this.formulaStyle = formulaStyle;
        const onModelAdd = (model: monaco.editor.IModel): void => {
            if (model.getLanguageId() !== _selector) {
                return;
            }

            let handle;
            const changeSubscription = model.onDidChangeContent(() => {
                clearTimeout(handle);
                handle = setTimeout(() => this._doValidate(model, setParsable, getEnabled), 500);
            });

            this._listener[model.uri.toString()] = {
                dispose() {
                    changeSubscription.dispose();
                    clearTimeout(handle);
                }
            };

            this._doValidate(model, setParsable, getEnabled)

        };

        const onModelRemoved = (model: monaco.editor.IModel): void => {
            monaco.editor.setModelMarkers(model, this._selector, []);
            const key = model.uri.toString();
            if (this._listener[key]) {
                this._listener[key].dispose();
                delete this._listener[key];
            }
        };

        this._disposables.push(monaco.editor.onDidCreateModel(onModelAdd));
        this._disposables.push(monaco.editor.onWillDisposeModel(onModelRemoved));
        this._disposables.push(monaco.editor.onDidChangeModelLanguage(event => {
            onModelRemoved(event.model);
            onModelAdd(event.model);
        }));

        this._disposables.push({
            dispose() {
                for (const model of monaco.editor.getModels()) {
                    onModelRemoved(model);
                }
            }
        });

        monaco.editor.getModels().forEach(onModelAdd);
    }

    public dispose(): void {
        this._disposables.forEach(d => d && d.dispose());
        this._disposables = [];
    }

    private async _doValidate(model: monaco.editor.ITextModel, setParsable, getEnabled): Promise<void> {
        if (model.isDisposed()) {
            // model was disposed in the meantime
            return;
        }
        if (model.getValue().substring(0, 1) !== '=') { // it is just a value rather than a formula. Note that Excel considers " =3" as a value
            this.enabled = getEnabled()
            this.analysisResult = [];
            monaco.editor.setModelMarkers(model, this._selector, []);
            setParsable(true);
            return;
        }
        try {
            let text = model.getValue();
            // console.log("model.editor.getVersionId", model.getVersionId())

            const promises: Promise<Diagnostic>[] = [];

            // let checkFormula("=vlookup(C31,O23:T30,2,false)+3", "A1")
            console.log("_doValidate: " + this.formulaStyle)
            let resultA = optimizeFormulaV4(text, this.formulaStyle);  // transform.ml type checkresult
            console.log("resultA", resultA);
            console.log("@validating pref" , this.preferences); 

            var noError = true;
            resultA.forEach(resultA1 => {
                if (resultA1.kind === "Error") {
                    noError = false
                }
            })

            resultA = resultA.filter(r => {
                if (r.kind === "Error") {
                    return true
                } else if (r.kind === "Optimization") {
                    let d = utilities.infoFromResult(r);
                    if (typeof this.preferences?.[d.code] !== "undefined") return this.preferences[d.code];
                    return true;
                }
            });
            // filter user preferences 
            //resultA = resultA.filter()

            resultA.forEach(resultA1 => {
                console.log("resultA1", resultA1);
                if (resultA1.kind === "Error") {
                    promises.push(Promise.resolve({
                        category: DiagnosticCategory.Error,
                        code: utilities.infoFromResult(resultA1).code,
                        file: undefined,
                        length: resultA1.ending - resultA1.start,
                        messageText: resultA1.errorMessage,
                        start: resultA1.start + 1
                    }))
                } else if (resultA1.kind === "Optimization") {
                    promises.push(Promise.resolve({ // see "interface Diagnostic" and "interface DiagnosticRelatedInformation" above
                        category: DiagnosticCategory.Warning, // DiagnosticCategory.Suggestion, category: DiagnosticCategory.Error,
                        code: utilities.infoFromResult(resultA1).code,
                        file: undefined,
                        length: resultA1.ending - resultA1.start,
                        messageText: utilities.infoFromResult(resultA1).diagnosticMessage,
                        start: resultA1.start + 1
                    }))
                }
            });

            let diagnostics = await Promise.all(promises);

            if (!diagnostics || model.isDisposed()) {
                // model was disposed in the meantime
                return;
            }

            console.log("diagnostics", diagnostics);

            const markers = diagnostics
                .map(d => this._convertDiagnostics(model, d));

            console.log("markers", markers)

            monaco.editor.setModelMarkers(model, this._selector, markers);

            this.enabled = getEnabled()
            this.analysisResult = resultA

            setParsable(noError) // initially setParsable(true) worked well, now if the editor becomes inactive after several hours, this line may be the cause
        } catch (error) { 
            console.log(error); console.log ("an error is raised with ", model.getValue()); 
            this.enabled = getEnabled()
            this.analysisResult = []
            setParsable(false) 
        }
    }

    private _convertDiagnostics(model: monaco.editor.ITextModel, diag: Diagnostic): monaco.editor.IMarkerData {
        const diagStart = diag.start || 0;
        // const diagLength = diag.length || 1;
        var diagLength;
        if (diag.length === undefined) diagLength = 1 
            else diagLength = diag.length   
        const { lineNumber: startLineNumber, column: startColumn } = model.getPositionAt(diagStart);
        const { lineNumber: endLineNumber, column: endColumn } = model.getPositionAt(diagStart + diagLength);

        return { // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.imarkerdata.html
            severity: this._tsDiagnosticCategoryToMarkerSeverity(diag.category),
            startLineNumber,
            startColumn,
            endLineNumber,
            endColumn,
            message: diag.messageText,
            code: diag.code.toString(),
            tags: diag.reportsUnnecessary ? [monaco.MarkerTag.Unnecessary] : [],
            source: diag.source,
            relatedInformation: this._convertRelatedInformation(model, diag.relatedInformation),
        };
    }

    private _convertRelatedInformation(model: monaco.editor.ITextModel, relatedInformation?: any[]): monaco.editor.IRelatedInformation[] | undefined {
        if (!relatedInformation) {
            return;
        }

        const result: monaco.editor.IRelatedInformation[] = [];
        relatedInformation.forEach((info) => {
            let relatedResource: monaco.editor.ITextModel | null = model;
            if (info.file) {
                const relatedResourceUri = monaco.Uri.parse(info.file.fileName);
                relatedResource = monaco.editor.getModel(relatedResourceUri);
            }

            if (!relatedResource) {
                return;
            }
            const infoStart = info.start || 0;
            const infoLength = info.length || 1;
            const { lineNumber: startLineNumber, column: startColumn } = relatedResource.getPositionAt(infoStart);
            const { lineNumber: endLineNumber, column: endColumn } = relatedResource.getPositionAt(infoStart + infoLength);

            result.push({ // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.irelatedinformation.html
                resource: relatedResource.uri,
                startLineNumber,
                startColumn,
                endLineNumber,
                endColumn,
                message: info.messageText
            });
        });
        return result;
    }

    private _tsDiagnosticCategoryToMarkerSeverity(category: DiagnosticCategory): monaco.MarkerSeverity {
        switch (category) {
            case DiagnosticCategory.Error: return monaco.MarkerSeverity.Error
            case DiagnosticCategory.Message: return monaco.MarkerSeverity.Info
            case DiagnosticCategory.Warning: return monaco.MarkerSeverity.Warning
            case DiagnosticCategory.Suggestion: return monaco.MarkerSeverity.Hint
        }
        return monaco.MarkerSeverity.Info;
    }

    protected _textSpanToRange(model: monaco.editor.ITextModel, span: any): monaco.IRange {
        let p1 = model.getPositionAt(span.start);
        let p2 = model.getPositionAt(span.start + span.length);
        let { lineNumber: startLineNumber, column: startColumn } = p1;
        let { lineNumber: endLineNumber, column: endColumn } = p2;
        return { startLineNumber, startColumn, endLineNumber, endColumn };
    }
}


export class LanguageServiceDefaultsImpl {

    private _onDidChange = new monaco.Emitter<void>();

    get onDidChange(): monaco.IEvent<void> {
        return this._onDidChange.event;
    }
    getDiagnosticsOptions() {
        return { diagnosticCodesToIgnore: [], noSyntaxValidation: true, noSemanticValidation: false, noSuggestionDiagnostics: true };
    }
}
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Utilities } from '@microsoft/office-js-helpers';

import Header from '../components/Main/Header';
import Theme from '../components/Theme';
import HeaderFooterOverflowLayout from './HeaderFooterOverflowLayout';
import { getNextFromUrl } from '../utils';
import { SpreadsheetCommunicatorFactory } from '../communicators/spreadsheet-communicator.factory';

const BasicLayout: React.FC<RouteComponentProps<any>> = props => {
  if (props.location.pathname === '/welcome' || props.location.pathname === '/welcome/') {
    const next = getNextFromUrl(props.location);
    if (next.startsWith('/funfun')) {
      return <Theme host={Utilities.host}>{props.children}</Theme>;
    }
  }

  if(SpreadsheetCommunicatorFactory.isInGoogle()){
    return <Theme host={Utilities.host}>{props.children}</Theme>;
  }

  if (props.location.pathname === '/socialLoginSuccess') {
    return <Theme host={Utilities.host}>{props.children}</Theme>;
  }

  if (props.location.pathname === '/redirect-document' || props.location.pathname === '/redirect-helpdesk') {
    return <Theme host={Utilities.host}>{props.children}</Theme>;
  }

  if (props.location.pathname === '/formula-editor-web' || props.location.pathname === '/formula-editor-web') {
    return <Theme host={Utilities.host}>{props.children}</Theme>;
  }

  // temporarily don't show the top bar
  if (props.location.pathname === '/formula-editor-addin' || props.location.pathname === '/formula-editor-addin') {
    return <Theme host={Utilities.host}>{props.children}</Theme>;
  }

  return (
    <Theme host={Utilities.host}>
      <HeaderFooterOverflowLayout
        fullscreen={true}
        header={<Header items={[]} farItems={[]} />}
        footer={<></>}
      >
        {props.children}
      </HeaderFooterOverflowLayout>
    </Theme>
  );
};

export default withRouter(BasicLayout);

import React from 'react';
import { getSignTokenLocal, saveSignToken } from '../services/auth-utils';
function close() {

  Office?.context?.ui?.messageParent('close');
  window?.close?.();

}
export default function () {

  window?.opener?.postMessage('getUser', window.origin);
  Office?.context?.ui?.messageParent('getUser');
  getSignTokenLocal().then((token) => {
    saveSignToken(token).finally(close);
  }).catch(close);

  return <div>Login Success</div>;
}

import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Form, Field, ErrorMessage, FormikProps } from 'formik';

import './index.css';
import { LoginOauthPayload } from '../../services/auth-oauth';
import { LoginPayload } from '../../services/auth';
import { Link } from 'dva/router';

interface FormValues extends LoginPayload { }

export interface LoginFormProps {
  login: (payload: LoginPayload) => void;
  loginOauth: (payload: LoginOauthPayload) => void;
  loginSSO: () => void;
  error?: string;
  ssoLoginError?:string; 
}

const Login = ({ error, ssoLoginError,  loginOauth, loginSSO, ...formik }: LoginFormProps & FormikProps<FormValues>) => {
  const loginGoogle = () => {
    loginOauth({ social: 'google' });
  };

  const loginLinkedin = () => {
    loginOauth({ social: 'linkedin' });
  };

  const loginTwitter = () => {
    loginOauth({ social: 'twitter' });
  };

  const loginMicrosoft = () => {
    loginOauth({ social: 'microsoft' });
  };

  const loginFacebook = () => {
    loginOauth({ social: 'facebook' });
  };

  const loginGithub = () => {
    loginOauth({ social: 'github' });
  };

  return (

    <div className="login-form">
      <h3 className="border-label"> Sign in <span>required</span></h3>
      <link
        rel="stylesheet"
        // href="https://cdn.bootcss.com/font-awesome/4.7.0/css/font-awesome.min.css"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
          <div className="social-login-wrapper" >

            <a onClick={loginSSO} className="social-login microsoft">
              <span className="icon fa fa-windows" />
              Quick Sign-in
            </a>
            {ssoLoginError && <div className="alert alert-danger" style={{margin:"4px" , width:"100%"}}>{ssoLoginError}</div>}

            <a onClick={loginGoogle} className="social-login">
              <span className="icon fa fa-google-plus" />
              Google
            </a>
            {/* <a onClick={loginLinkedin} className="social-login linkedin">
              <span className="icon fa fa-linkedin" />
              Linkedin
            </a> */}
            <a onClick={loginMicrosoft} className="social-login microsoft">
              <span className="icon fa fa-windows" />
              Microsoft
            </a>
            <a onClick={loginTwitter} className="social-login twitter">
              <span className="icon fa fa-twitter" />
              Twitter
            </a>
            <a onClick={loginFacebook} className="social-login facebook">
              <span className="icon fa fa-facebook" />
              Facebook
            </a>
            <a onClick={loginGithub} className="social-login github">
              <span className="icon fa fa-github" />
              Github
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
          <div className="login-form-wrapper">
            <div className="formContainer">
              <Form className="form">

                <div className=" text-left" style={{ marginTop: "10px", width: "100%" }}>
                    <p style={{ padding:"0" , margin :"0" , color:"#888" }}>Sign in using an email address</p>
                </div>
                <Field className="input" style={{ marginTop: "10px", width: "100%" }} always-show-detailstype="text" name="email" placeholder="you@example.com" />

                <Field className="input" style={{ marginTop: "10px", width: "100%" }} type="password" name="password" placeholder="******" />

                <div className="reset-password text-left" style={{ marginTop: "10px", width: "100%" }}>
                  <Link to='/reset'>Forgot your password?</Link>
                </div>

                <button className="button" style={{ marginTop: "10px", width: "100%" }} type="submit" >
                  Sign In
                </button>
              </Form>
            </div>
            <div className="messages-wrapper">
              {formik.errors.email && formik.touched.email && (
                <div className="alert alert-danger">
                  <ErrorMessage name="email" />
                </div>
              )}
              {formik.errors.password && formik.touched.password && (
                <div className="alert alert-danger">
                  <ErrorMessage name="password" />
                </div>
              )}
              {error && <div className="alert alert-danger">{error}</div>}
            </div>


          </div>
        </div>

      </div>

    </div >
  );
};

const LoginValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(6)
    .max(16)
    .required(),
});

const LoginForm = withFormik<LoginFormProps, FormValues>({
  // Handles our submission
  handleSubmit: (values, { props: { login } }) => {
    login(values);
  },
  validationSchema: LoginValidation,
})(Login);

export default LoginForm;

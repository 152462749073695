import { State } from '../store/reducer';
import { UserType } from '../services/auth';
import { DEFAULT_PATH } from '../constants';

export const getIsLoggedIn = (state: State): boolean => {
  return !!state.auth.user && state.auth.user.type !== UserType.ANONYM;
};

export const getIsLoggingInOrOut = (state: State): boolean => {
  return state.auth.IsLoggingInOrOut;
};

export const getNext = (state: State): string => {
  return state.auth.next || DEFAULT_PATH;
};

export const getUserName = (state: State): string => {
  if (state.auth.user) {
    return state.auth.user.name;
  }
  return 'UNKNOWN';
};

export const getUserEmail = (state: State): string | undefined => {
  if (state.auth.user) {
    return state.auth.user.email;
  }
  return undefined;
};

export const getUid = (state: State): string => {
  if (state.auth.user) {
    return state.auth.user._id
  }
  return 'UNKNOWN'
}

export const getPreferences = (state: State): Object | undefined => {
  return state.auth.preferences;
};
import axios from 'axios';
import { saveSignToken } from './auth-utils';
import { fetch } from './auth';
declare var OfficeRuntime;

async function verifyToken(token) {
    let response = await axios.get(`${AUTH_BACKEND_URL}/10studio/auth/sso`, {
        params: {
            auth: token
        }
    });
    return response;
}


export async function autoLoginSSO() {
    let bootstrapToken = await OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: false, allowConsentPrompt: false });
    let userToken = (await verifyToken(bootstrapToken)).data.token;
    await saveSignToken(userToken);
    return fetch();
}


export async function loginSSO() {
    try {
    let bootstrapToken = await OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true, allowConsentPrompt: true, forMSGraphAccess: true });
    let userToken = (await verifyToken(bootstrapToken)).data.token;
    await saveSignToken(userToken);
    return fetch();
    } catch (error) {
    console.log(error)
    }

}

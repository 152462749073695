
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import './index.scss';


interface IState {
  url: string;
}
class RedirectDocumentPage extends React.Component<RouteComponentProps<any>, IState> {
  constructor(props) {
    super(props);
    this.state = { url: "https://www.10studio.tech/docs/introduction/" };

  }
  render() {



    return (
      <div className="redirectPageContainer">
        <div className="container">
          <div className="row">
            <p>
              New features and shortcuts:
            </p>
            <b>Evaluate a sub-formula:</b> make sure the full formula is valid, then select the sub-formula.
              The evaluation value will appear when you
              hover over the selection with the mouse or by keyboard shortcut Ctrl+K Ctrl+I (Windows) or Command+K Command+I (Mac).
          </div>
          <div className="row" >
            <PrimaryButton className="btn-success"
              text="Documentation"
              onClick={() => {
                window.open(this.state.url);
              }}
            />
          </div>
          <div className="row">
            <p>
            Check out our online video courses for spreadsheet programming in general:
            </p>

            <PrimaryButton 
              text="Spreadsheet Language and Programming (English version)"
              onClick={() => {
                window.open("https://go.10studio.tech/LspkNR");
              }}
            />
            <PrimaryButton 
              text="表格语言和编程（中文版）"
              onClick={() => {
                window.open("https://go.10studio.tech/xqTGYK");
              }}
            />
          </div>
 
        </div></div>

    );

  }
}

export default withRouter(RedirectDocumentPage);
import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'dva';

// office-ui-fabric-react
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PersonaSize, PersonaCoin } from 'office-ui-fabric-react/lib/Persona';
import { Icon } from 'office-ui-fabric-react';

// common
import CommonHeader from '../../Header';
import { ThemeContext } from '../../Theme';
//local
import selectors from '../../../selectors';

// redux
import { State as IReduxState } from '../../../store/reducer';
import { IHeaderItem } from '../../../selectors/header';

export const convertActionCreatorToOnClick = (
  item: IHeaderItem,
  dispatch: any, // TODO: why is this complaining about being a Dispatch?
): IHeaderItem => ({
  ...item,
  onClick: item.actionCreator
    ? () => item.actionCreator && dispatch(item.actionCreator())
    : undefined,

  subMenuProps: item.subMenuProps
    ? {
        ...item.subMenuProps,
        items:
          item.subMenuProps.items !== undefined
            ? item.subMenuProps.items.map(subItem => ({
                ...subItem,
                onClick: subItem.actionCreator
                  ? () => dispatch(subItem.actionCreator())
                  : undefined,
              }))
            : [],
      }
    : undefined,
});

interface IProps {
  items: IHeaderItem[];
  farItems: IHeaderItem[];
  dispatch: Dispatch<any>;

  isLoggedIn: boolean;
  isLoggingInOrOut: boolean;
  profilePicUrl?: string;
}

interface IState {
  isSolutionSettingsVisible?: boolean;
}

// tslint:disable: jsx-no-multiline-js
class Header extends Component<IProps, IState> {
  private renderItem = (item: IHeaderItem): IHeaderItem => {
    const customRenderIcons = this.getCustomOnRenderIconButtons();
    const onClickReadyItem = convertActionCreatorToOnClick(item, this.props.dispatch);
    if (item.key in customRenderIcons) {
      return {
        ...onClickReadyItem,
        onRenderIcon: customRenderIcons[item.key],
      };
    } else {
      return onClickReadyItem;
    }
  };

  private getCustomOnRenderIconButtons = (): { [key: string]: () => JSX.Element } => {
    const { isLoggedIn, isLoggingInOrOut, profilePicUrl } = this.props;
    const loginElement = isLoggedIn ? (
      <ThemeContext.Consumer>
        {theme => (
          <PersonaCoin
            imageUrl={profilePicUrl}
            size={PersonaSize.size28}
            initialsColor="white"
            styles={{
              initials: {
                color: (theme && theme.primary) || 'black',
              },
            }}
          />
        )}
      </ThemeContext.Consumer>
    ) : (
      // <Icon iconName="Leave" title="SignIn" ariaLabel="SignIn" />
      <Icon title="SignIn" ariaLabel="SignIn" />
    );
    return {
      account: () => (
        // <div style={{ width: '28px', overflow: 'hidden' }}>
        <div>
          {isLoggingInOrOut ? <Spinner size={SpinnerSize.medium} /> : loginElement}
        </div>
      ),
    };
  };

  render() {
    const { items, farItems } = this.props;

    return (
      <>
      <div className="header">
        <CommonHeader
          items={items.map((item: IHeaderItem) => this.renderItem(item))}
          farItems={farItems.map((item: IHeaderItem) => this.renderItem(item))}
        />
        </div>
      </>
    );
  }
}

// tslint:enable: jsx-no-multiline-js
export default connect((state: IReduxState) => ({
  items: selectors.header.getItems(state),
  farItems: selectors.header.getFarItems(state),
  isLoggedIn: selectors.auth.getIsLoggedIn(state),
  isLoggingInOrOut: selectors.auth.getIsLoggingInOrOut(state),
}))(Header);

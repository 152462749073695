import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Form, Field, ErrorMessage, FormikProps } from 'formik';
import axios from 'axios';

import   '../LoginForm/index.css';
import { saveSignToken } from '../../services/auth-utils';

interface FormValues {
  email: string;
  password: string;
  password2: string;
  extra?: string;
}

const Register = ({ status, ...formik }: FormikProps<FormValues>) => {
  return (
   
    <div className="login-form sign-form">
      <h3 className="border-label"> Register </h3>
      <link
        rel="stylesheet"
        // href="https://cdn.bootcss.com/font-awesome/4.7.0/css/font-awesome.min.css"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
          <div className="login-form-wrapper sign-form-wrapper">
            <div className="formContainer">
              <Form className="form">
                <Field className="input" type="text" name="email" placeholder="you@example.com" />
                <Field className="input" type="password" name="password" placeholder="password" />
                <Field
                  className="input"
                  type="password"
                  name="password2"
                  placeholder="password(repeated)"
                />

                <button className="button" type="submit">
                  Sign Up
                 </button>
              </Form>
            </div>
            <div className="messages-wrapper">
              {formik.errors.email && <div className="alert alert-danger email-error">
                <ErrorMessage name="email" />
              </div>
              }
              {formik.errors.password && formik.touched.password &&                 

              <div className="alert alert-danger password-error">
                <ErrorMessage name="password" />
              </div>
              }
              {formik.errors.password2 &&  
              
              <div className="alert alert-danger password2-error">
                <ErrorMessage name="password2" />
              </div>
              }
              {formik.errors.extra && 
              <div className="alert alert-danger extra-error">
                <ErrorMessage name="extra" />
              </div>
              }
              {status && status.msg && 
              <div className="alert alert-success msg-status">{status.msg}</div>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RegisterValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(6)
    .max(16)
    .required(),
});

const RigisterForm = withFormik<{}, FormValues>({
  // Handles our submission
  handleSubmit: async (values, { setSubmitting, setStatus }) => {
    // This is where you could send the submitted values to the backend
    try {
      const res = await axios.post(`${FUNFUN_URL}/httpOnly/register`, {
        email: values.email,
        password: values.password,
        passwordRepeat: values.password2,
        type: 'normal',
      });
      const token = res.data.token;
      await saveSignToken(token);
      window.location.href = '/signin';
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log('register Error: ', e.response.data);
      if (e.response.data.message) {
        setStatus({ msg: e.response.data.message });
      } else {
        setStatus(JSON.stringify(e.response.data));
      }
    }
  },
  validationSchema: RegisterValidation,
})(Register);

export default RigisterForm;

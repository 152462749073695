import React, { lazy, ChangeEvent, useEffect } from 'react';
import { withRouter , RouteComponentProps} from 'react-router';
import { State as ReduxState } from '../../store/reducer';
import { connect } from 'dva';
import selectors from '../../selectors';
import { PrimaryButton } from '@fluentui/react';
import { ISpreadsheetCommunicator } from '../../communicators/abstract-spreadsheet-communicator';
import { SpreadsheetCommunicatorFactory } from '../../communicators/spreadsheet-communicator.factory';
import GalleryList from '../ScriptLab/GalleryList';

import '../ScriptLab/packages-editor-src-index.css';

import './style.css';

import Content from '../ScriptLab/Content';
import socialLoginSuccess from '../socialLoginSuccess';
interface IState {
    hasNothingAbout: boolean
		items_added: any
		items_no_added: any
}

class ExcelLibraries extends React.Component<RouteComponentProps<any>, IState> {
	communicator?: ISpreadsheetCommunicator;
	constructor(props) {
			super(props);
			this.state = {
					hasNothingAbout: true,
					items_added: [],
					items_no_added: []
			}

			try {
					this.communicator = SpreadsheetCommunicatorFactory.createContextCommunicator();
					} catch (ex) {
			}
	}

	openExcelLibrary = (key: string) => {
		if (key === "AutoXL") {
				this.props.history.push("/AutoXL")
		}
		console.log("openExcelLibrary")
	};

	componentDidMount() {
			this.communicator?.hasNothingAbout("AutoXL").then((x: boolean) => {
				const items_wo_autoxl = [
					{ key: "DateXL", title: "DateXL (coming soon)", description: "An extension of date and time functions.",
					onClick: () => {} },
					{ key: "FinanceXL", title: "FinanceXL (coming soon)", description: "A library of financial functions.",
					onClick: () => {} },
					{ key: "EngineerXL", title: "EngineerXL (coming soon)", description: "A library of engineering functions.",
					onClick: () => {} }
				];
		
				const items_autoxl = 
					[{ key: "AutoXL", title: "AutoXL", description: 
							"A fundamental and powerful library to automate manual tasks, by extensions of lookup and references functions, and elementary functions for array and set, etc.",
							onClick: () => this.openExcelLibrary("AutoXL") }];

				this.setState({ hasNothingAbout: x })
				if (x) {
					this.setState({ items_added: [], items_no_added: items_autoxl.concat(items_wo_autoxl) })
				} else {
					this.setState({ items_added: items_autoxl, items_no_added: items_wo_autoxl })
				};
			})
	}
	
  render() {
    return (
        <Content title="Libraries" description="">
            <GalleryList
                testId = "added-libraries"
                title = "Libraries added to this workbook"
                items = {this.state.items_added}
            />
            <GalleryList
                testId = "other-libraries"
                title = "Other available libraries"
                items = {this.state.items_no_added
                    .map(sol => ({
                        key: sol.key,
                        title: sol.title,
                        description: sol.description,
                        onClick: sol.onClick,
                        isActive: false,
                    }))}
            />
        </Content>
      );
  }
}

export default connect((state: ReduxState) => ({
  app: selectors.app.selectAppName(state),
  uid: selectors.auth.getUid(state)
}))(withRouter(ExcelLibraries));

import { TextEdit, Position, TextDocument, FormattingOptions, Range, Diagnostic, CodeAction, CancellationToken, TextDocumentEdit, WorkspaceEdit, Command } from 'vscode-languageserver-protocol';
import * as kpiServices from '../../services/kpi';
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import * as monaco from 'monaco-editor';
import * as utilities from './utilities'
import { AppName } from '../../models/app';
declare var getFormatWidthLimitV4, formatFormulaWrapV4;

function kpiServiceLog(data){
	//@ts-ignore
	if(window.__$$InGoogle) {
		data.app = AppName.GoogleSheetFormulaEditor; 
	}else {
		data.app = AppName.FormulaEditor; 
	}

	//@ts-ignore
	// data protection
	// kpiServices.post(data); 
}
export class FormulaLanguageService {
	currentModelVersionIdProvideDocumentRangeFormattingEdits: any;
	currentModelVersionIdProvideHover: any;
	currentModelVersionIdProvideCodeActions: any;
	widthLimit: any;
	autoWidthLimit: any;
	formulaStyle: any;
	numberDecimalSeparator: any;
	displayLanguage: any;
	contentLanguage: any;

	constructor() {
		this.widthLimit = undefined
		this.autoWidthLimit = undefined
		console.log(`widthLimit in constructor: ${this.widthLimit}`)
		console.log(`autoWidthLimit in constructor: ${this.autoWidthLimit}`)
	}

	_formatFormula(formula, widthLimit) {
		// console.log("_formatFormula");
		// console.log(JSON.stringify(formula));
		if (formula[0] === '=') {
			console.log("widthLimit: ", widthLimit);
			console.log(this.formulaStyle);
			var options;
			if (this.formulaStyle === "R1C1")
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: ".", 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			else 
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: this.numberDecimalSeparator, 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			let code = formatFormulaWrapV4(formula.slice(1), options, widthLimit);
			let lines = code.split('\n');
			lines = lines.map((line, i) => (i === 0 ? '= ' + line : '  ' + line));
			return lines.join('\n');
		} else {
			console.log(this.formulaStyle);
			var options;
			if (this.formulaStyle === "R1C1") 
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: ".", 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			else 
				options = { formulaStyle: this.formulaStyle, numberDecimalSeparator: this.numberDecimalSeparator, 
					displayLanguage: this.displayLanguage, contentLanguage: this.contentLanguage };
			return formatFormulaWrapV4(formula, options, widthLimit);
		}
	};

	formatFormulaLSP(formula) {
		let formulaR
		formula = formula.trimLeft();
		let widthLimit = this.widthLimit;
		if (this.autoWidthLimit) {
			widthLimit = getFormatWidthLimitV4(formula, 40);
			console.log(`getFormatWidthLimit: ${widthLimit}`)

			const action: any = { theType: 'getFormatWidthLimit', getFormatWidthLimit: {} }
			action.getFormatWidthLimit.formulaInitial = formula;
			action.getFormatWidthLimit.base = 40
			action.getFormatWidthLimit.widthLimitResult = widthLimit
			kpiServiceLog({action}); 
		}
		const action: any = { theType: 'formatFormula', formatFormula: {} }
		action.formatFormula.formulaInitial = formula;
		action.formatFormula.widthLimitInput = widthLimit;

		try {
			console.log("before this._formatFormula(formula, widthLimit)")
			formulaR = this._formatFormula(formula, widthLimit);
		} catch (e) {
			console.log(e.toString())
			// when calling OCaml function failed, set code to raw formula
			action.formatFormula.formulaResult = formulaR;
			action.formatFormula.status = "failed";
			action.formatFormula.errorMessage = e.toString();
			kpiServiceLog({action}); 
			throw e;
		}

		if (widthLimit != this.widthLimit) {
			// this.widthLimit = widthLimit
			action.formatFormula.formulaResult = formulaR;
			action.formatFormula.status = "successful";
		} else {
			action.formatFormula.formulaResult = formulaR;
			action.formatFormula.status = "successful";
		}

		kpiServiceLog({action}); 

		return formulaR
	};

	format(document: TextDocument, range: Range, option: FormattingOptions): TextEdit[] {
        console.log("format in formula-language-server.ts")
		let edits : any[] = [];
		edits.push(TextEdit.del(range));

		let t = this.formatFormulaLSP(document.getText())

		edits.push(TextEdit.insert(Position.create(0, 0), t));
		return edits;
	}

    codeAction(model: monaco.editor.ITextModel, document: TextDocument, 
        diagnostic: Diagnostic, // defined in vscode-languageserver-types, rather than Diagnostic in language-feature.ts
        uri: string,
        analysisResult: any): CodeAction | Command | null {

        let edits : any
        let action : any

        analysisResult.forEach(resultA1 => {
			if (resultA1.kind === "Optimization" || resultA1.kind === "Error") {
				let start = resultA1.start + 1 || 0;
				let length = resultA1.ending - resultA1.start || 1; // {to check}: this may be wrong for Error, when it's just a position
				const { lineNumber: startLineNumber, column: startColumn } = model.getPositionAt(start);
				const { lineNumber: endLineNumber, column: endColumn } = model.getPositionAt(start + length);
				if ((startLineNumber == diagnostic.range.start.line + 1) && (startColumn == diagnostic.range.start.character + 1) &&
					(endLineNumber == diagnostic.range.end.line + 1) && (endColumn == diagnostic.range.end.character + 1) &&
					(utilities.infoFromResult(resultA1).code == diagnostic.code) &&
					(utilities.infoFromResult(resultA1).quickFix != "NA")) {    
						console.log("diagnostic.range", diagnostic.range)
						// console.log(startLineNumber);
						// console.log(startColumn);
						// console.log(endLineNumber);
						// console.log(endColumn);        
						edits = [TextEdit.replace(diagnostic.range, utilities.infoFromResult(resultA1).optimized)];
						console.log("result A1", resultA1);
						console.log("diagnostic", diagnostic);
						action = CodeAction.create(utilities.infoFromResult(resultA1).quickFix, { changes: { uri: edits } }, "quickfix");
						action.diagnostics = [diagnostic];
					}
			}
        })
        return action
	}

	public async provideHover(model: monaco.editor.ITextModel, position: monaco.Position, token: CancellationToken, analysisResult: any, selection, evaluateFormula): Promise<monaco.languages.Hover | undefined> {
		/*  getModelMarkers()[0]
		    code: "112"
            endColumn: 12
            endLineNumber: 1
            message: " "
            owner: "formula"
            relatedInformation: undefined
            resource: _URI {scheme: "inmemory", authority: "model.json", path: "", query: "", fragment: "", …}
            severity: 8
            source: undefined
            startColumn: 5
            startLineNumber: 1
            tags: []
        */

		function adjust(x) {
			if (x === true) return "TRUE"
			if (x === false) return "FALSE"
			const errorSet = new Set(["#DIV/0", "#N/A", "#NAME?", "#NULL!", "#NUM!", "#REF!", "#VALUE!", "#SPILL!"])
			if (errorSet.has(x)) return x

			return JSON.stringify(x) 
		}

		console.log("monaco.editor.getModelMarkers({})", monaco.editor.getModelMarkers({}))
		console.log("providerHover ", analysisResult)

		let contents: any[] = [];

		if (model.getValueInRange(selection) != ""
			&& selection.getStartPosition().isBeforeOrEqual(position) 
			&& position.isBeforeOrEqual(selection.getEndPosition())) {
			// console.log("model.getValue()", model.getValue())
			// console.log("position", position)
			const beforeSelection = model.getValueInRange({startLineNumber: 1, startColumn: 1, endLineNumber: selection.startLineNumber, endColumn: selection.startColumn })
			// console.log("startP", beforeSelection.length);
			const x = await evaluateFormula(model.getValueInRange(selection), model.getValue(), beforeSelection.length)
			if ((x === null) || (x === undefined)) {
			// if (x === null) {
				contents.push({ value: adjust(x) })
			} else if ((x.length === 1) && (x[0].length === 1)) {
				contents.push({ value: adjust(x[0][0]) })
			} else {
				var st = "";
				for (var i = 0; i < x.length; i++) {
					var r = ""
					for (var j = 0; j < x[i].length; j++) {
						r = r + "<td>" + adjust(x[i][j]) + "</td>"
					}
					st = st + "<tr>" + r + "</tr>"
				} 	
				st = "<table>" + st + "</table>"
				console.log("st", st)
				contents.push({ value: st, supportHtml: true })
			}
		}
		
		let rangeStart = -1; 
		let range:any = null;
		analysisResult.forEach(resultA1 => {
			console.log("resultA1here", resultA1);
			var start, length;
			if (resultA1.ending != resultA1.start) {
				start = resultA1.start + 1 || 0;
				length = resultA1.ending - resultA1.start || 1
			} else {
				start = resultA1.start || 0;
				length = 2 // when EOF is reached, the real length may be 1 rather than 2
			}
			const { lineNumber: startLineNumber, column: startColumn } = model.getPositionAt(start);
			const { lineNumber: endLineNumber, column: endColumn } = model.getPositionAt(start + length);
			let senseStart = new monaco.Position(startLineNumber, startColumn - 1);
			// the reason why we may set "startColumn - 1" above is that hovering on markers and diagnostics shows "View Problem", 
			// and the start of the sensor is a little bit on the left, so we make senseStart here to match. 
			// Otherwise, we may only see "View Problem" without contents/messages when we hover on the left.
			// see https://github.com/microsoft/monaco-editor/issues/3065#issuecomment-1095135812
			let senseEnd = new monaco.Position(endLineNumber, endColumn + 1);
			// "endColumn + 1" for similar reason as above

			// console.log("resultA1.start: ", resultA1.start);
			// console.log("resultA1.ending: ", resultA1.ending);
			// console.log("start: ", start);
			// console.log("length: ", length);
			// console.log("senseStart, startColumn: ", startColumn);
			// console.log("senseStart, endColumn :", endColumn);
			// console.log("position.column: ", position.column);
			
			if (senseStart.isBeforeOrEqual(position) && position.isBeforeOrEqual(senseEnd)) {
				if (resultA1.kind === "Error") {
					contents.push({ value: resultA1.errorMessage, isTrusted: false })					
				} else if (resultA1.kind === "Optimization") {
					contents.push({ value: utilities.infoFromResult(resultA1).contentMarkdown, isTrusted: false })
				}
				if (rangeStart == -1 || rangeStart > start) { // select the largest range 
					range = { startLineNumber, startColumn, endLineNumber, endColumn };
					rangeStart = start ; 
				}
			}
		})

		console.log("contents", contents)
		console.log("range", range)

		return { contents: contents , range };
	}
}
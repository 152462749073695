import { push } from 'react-router-redux';

import { ICommandBarItemProps } from 'office-ui-fabric-react';

import { State } from '../store/reducer';
import { DEFAULT_PATH } from '../constants';
import { getIsLoggedIn, getNext, getUserName, getIsLoggingInOrOut } from './auth';

export interface IHeaderItem extends ICommandBarItemProps {
  actionCreator?: () => { type: string; payload?: any };
}

export const getItems = (state: State): IHeaderItem[] => {
  if (state.routing.location && state.routing.location.pathname === "/AutoXL") {
    const goBack = () => {
      return push(`/excel-libraries`);
    }
    const username = getUserName(state);
    return [
      {
        key: 'goBack',
        text: 'Go Back',
        iconOnly: true,
        iconProps: { iconName: 'Back' },
        actionCreator: goBack
      }
    ]
  } else return [];
};

export const getNextPath = (state: State): string => {
  let path: string = DEFAULT_PATH;
  if (state.routing.location) {
    path = state.routing.location.pathname;
    if (path === '/sign') {
      path = getNext(state);
    }
  }
  return path;
};

export const getFarItems = (state: State): IHeaderItem[] => {
  if (state.routing.location && (
    state.routing.location.pathname === "/AutoXL" ||
    state.routing.location.pathname === "/excel-libraries")) {
    return []
  } else {
    const isLoggedIn: boolean = getIsLoggedIn(state);
    const isLoggingInOrOut: boolean = getIsLoggingInOrOut(state);
    const path = getNextPath(state);
    const logout = () => ({
      type: 'auth/logout',
    });
    const login = () => {
      return push(`/sign?next=${path}`);
    };
    const toMySubscription = () => {
      return push(`/my-subscription`);
    };
    const username = getUserName(state);
    return [
      {
        key: 'account',
        text: isLoggedIn ? username : 'Sign In',
        ariaLabel: isLoggedIn ? 'Sign Out' : 'Sign In',
        subMenuProps: isLoggedIn
          ? {
              items: [
                {
                  key: 'signout',
                  text: 'Sign Out',
                  actionCreator: logout,
                },
              ],
            }
          : undefined,
        iconOnly: false,
        actionCreator: isLoggingInOrOut ? undefined : !isLoggedIn ? login : undefined,
      },
    ];
  }
};
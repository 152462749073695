import React, { Suspense, lazy } from 'react';
import { MonacoToProtocolConverter, ProtocolToMonacoConverter } from '@codingame/monaco-languageclient/lib/monaco-converter';
// import { MonacoToProtocolConverter, ProtocolToMonacoConverter } from 'monaco-languageclient/lib/monaco-converter';
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import * as monaco from 'monaco-editor';
import { FormulaLanguageService } from './formula-language-server';
import { DiagnosticsAdapter } from './language-feature';
import { TextDocument } from 'vscode-languageserver-protocol';
import { TextEdit, Position } from 'vscode-languageserver-protocol';
import * as formula from './formula';
import { connect } from 'dva';
import { getPreferences, getIsLoggingInOrOut } from '../../selectors/auth';
const LANGUAGE_ID_A1 = 'formula_A1'; // used by Excel in A1 mode + Google Sheets
const LANGUAGE_ID_R1C1 = 'formula_R1C1'; // used by Excel in R1C1 mode
const MODEL_URI = 'inmemory://model.json';
const MonacoEditor = lazy(() => import('react-monaco-editor'));

// create the Monaco editor
const value = `IF ( A , B , C `;

const m2p = new MonacoToProtocolConverter(monaco);
const p2m = new ProtocolToMonacoConverter(monaco);

function createDocument(model) {
  // return TextDocument.create(MODEL_URI, model.getModeId(), model.getVersionId(), model.getValue());
  return TextDocument.create(MODEL_URI, model.getLanguageId(), model.getVersionId(), model.getValue());
}

monaco.languages.register({ id: LANGUAGE_ID_A1, extensions: [], aliases: [], mimetypes: [], });
monaco.languages.register({ id: LANGUAGE_ID_R1C1, extensions: [], aliases: [], mimetypes: [], });

monaco.languages.setLanguageConfiguration(LANGUAGE_ID_A1, formula.conf);
monaco.languages.setLanguageConfiguration(LANGUAGE_ID_R1C1, formula.conf);

monaco.languages.setMonarchTokensProvider(LANGUAGE_ID_A1, formula.language);
monaco.languages.setMonarchTokensProvider(LANGUAGE_ID_R1C1, formula.language);

let formulaLanguageService_A1 = new FormulaLanguageService();
let formulaLanguageService_R1C1 = new FormulaLanguageService();

function _provideDocumentRangeFormattingEdits(model, range, options, token, formulaLanguageService) {
  // if (model.getVersionId() != formulaLanguageService.currentModelVersionIdprovideDocumentRangeFormattingEdits) {
  console.log("call provideDocumentRangeFormattingEdits", model.getVersionId())
  const document = createDocument(model);
  const edits = formulaLanguageService.format(document, m2p.asRange(range), m2p.asFormattingOptions(options));
  formulaLanguageService.currentModelVersionIdprovideDocumentRangeFormattingEdits = model.getVersionId()
  console.log("call currentModelVersionIdprovideDocumentRangeFormattingEdits", formulaLanguageService.currentModelVersionIdprovideDocumentRangeFormattingEdits)
  return p2m.asTextEdits(edits)
}

function setupModeR1C1(optimizer = true, preferences = {}, editor, evaluateFormula, setParsable, getEnabled) {
  monaco.languages.registerDocumentRangeFormattingEditProvider(LANGUAGE_ID_R1C1, {
    provideDocumentRangeFormattingEdits(model, range, options, token) {
      return _provideDocumentRangeFormattingEdits(model, range, options, token, formulaLanguageService_R1C1)
    }
  });

  // if we don't want hinting, we don't register HoverProvider and CodeActionProvider
  if (!optimizer) return;

  let da_R1C1 = new DiagnosticsAdapter(LANGUAGE_ID_R1C1, "R1C1", preferences, setParsable, getEnabled)

  monaco.languages.registerHoverProvider(LANGUAGE_ID_R1C1, {
    provideHover: (model, position, token) => {
      if (!da_R1C1.enabled) return null;
      console.log("call provideHover", model.getVersionId())
      formulaLanguageService_R1C1.currentModelVersionIdProvideHover = model.getVersionId()
      console.log("call currentModelVersionIdProvideHover", formulaLanguageService_R1C1.currentModelVersionIdProvideHover)
      return formulaLanguageService_R1C1.provideHover(model, position, token, da_R1C1.analysisResult, editor.getSelection(), evaluateFormula);
    }
  });

  monaco.languages.registerCodeActionProvider(LANGUAGE_ID_R1C1, {
    provideCodeActions: (model, _range, context, token) => {
      if (!da_R1C1.enabled) return null;
      // console.log("_range", _range); // not sure if it's really used
      console.log("context", context)
      console.log("call provideCodeActions", model.getVersionId())
      const document = createDocument(model);
      const uri = model.uri.toString();
      const actions = context.markers.map(marker => m2p.asDiagnostic(marker)).map(diagnostic => {
        let x = formulaLanguageService_R1C1.codeAction(model, document, diagnostic, uri, da_R1C1.analysisResult);
        console.log("codeAction", x)
        return x
      }).filter(action => action != null)
        .map(action => p2m.asCodeAction(action))
        .map(action => (
          {
            ...action,
            edit: {
              ...action.edit,
              edits: action.edit.edits.map(e => ({
                ...e,
                resource: model.uri,
                edit: e.edits[0],
              }))
                .map(({ edits, ...rest }) => rest)
            }
          }
        ));
      formulaLanguageService_R1C1.currentModelVersionIdProvideCodeActions = model.getVersionId()
      console.log("call currentModelVersionIdProvideCodeActions", formulaLanguageService_R1C1.currentModelVersionIdProvideCodeActions)
      return {
        actions: actions,
        dispose: () => { }
      }
    } 
  });

  return da_R1C1;
}
function setupModeA1(optimizer = true, preferences = {}, editor, evaluateFormula, setParsable, getEnabled) {
  monaco.languages.registerDocumentRangeFormattingEditProvider(LANGUAGE_ID_A1, {
    provideDocumentRangeFormattingEdits(model, range, options, token) {
      return _provideDocumentRangeFormattingEdits(model, range, options, token, formulaLanguageService_A1)
    }
  });
  // if we don't want hinting, we don't register HoverProvider and CodeActionProvider
  if (!optimizer) return;

  let da_A1 = new DiagnosticsAdapter(LANGUAGE_ID_A1, "A1", preferences, setParsable, getEnabled)
  // console.log("da_A1.analysisResult", da_A1.analysisResult)

  monaco.languages.registerHoverProvider(LANGUAGE_ID_A1, {
    provideHover: (model, position, token) => {
      if (!da_A1.enabled) return null;  
      console.log("call provideHover", model.getVersionId())
      formulaLanguageService_A1.currentModelVersionIdProvideHover = model.getVersionId()
      console.log("call currentModelVersionIdProvideHover", formulaLanguageService_A1.currentModelVersionIdProvideHover)
      return formulaLanguageService_A1.provideHover(model, position, token, da_A1.analysisResult, editor.getSelection(), evaluateFormula);
    }
  });

  // don't know why we may have "formulaLanguageService.codeAction is not a function" error if we make a function provideCodeActions: (model, _range, context, token, formulaLanguageService, da)for the repetitive part below.
  monaco.languages.registerCodeActionProvider(LANGUAGE_ID_A1, {
    provideCodeActions: (model, _range, context, token) => {
      if (!da_A1.enabled) return null;
      // console.log("_range", _range); // not sure if it's really used
      console.log("context", context)
      console.log("call provideCodeActions", model.getVersionId())
      const document = createDocument(model);
      const uri = model.uri.toString();
      const actions = context.markers.map(marker => m2p.asDiagnostic(marker)).map(diagnostic => {
        let x = formulaLanguageService_A1.codeAction(model, document, diagnostic, uri, da_A1.analysisResult);
        console.log("codeAction", x)
        return x
      }).filter(action => action != null)
        .map(action => p2m.asCodeAction(action))
        .map(action => (
          {
            ...action,
            edit: {
              ...action.edit,
              edits: action.edit.edits.map(e => ({
                ...e,
                resource: model.uri,
                // edit: e.edits[0], // previously it was coded like that
                edit: e.edit
              }))
                .map(({ edits, ...rest }) => rest)
            }
          }
        ));
      formulaLanguageService_A1.currentModelVersionIdProvideCodeActions = model.getVersionId()
      console.log("call currentModelVersionIdProvideCodeActions", formulaLanguageService_A1.currentModelVersionIdProvideCodeActions)
      return {
        actions: actions,
        dispose: () => { }
      }
    }
  });

  return da_A1;
}

class ResizableMonacoEditor extends React.Component {
  diagnosticsAdapterA1 = null;
  diagnosticsAdapterR1C1 = null;

  _handleEditorDidMount(editor, monaco) {

    monaco.languages.onLanguage(LANGUAGE_ID_A1, () => {
      this.diagnosticsAdapterA1 = setupModeA1(this.props.optimizer, this.props.preferences, editor, this.props.evaluateFormula, this.props.setParsable, this.props.getEnabled);
    });
    monaco.languages.onLanguage(LANGUAGE_ID_R1C1, () => {
      this.diagnosticsAdapterR1C1 = setupModeR1C1(this.props.optimizer, this.props.preferences, editor, this.props.evaluateFormula, this.props.setParsable, this.props.getEnabled);
    });
    this.monacoFormulaModel_A1 = monaco.editor.createModel(value, LANGUAGE_ID_A1);
    this.monacoFormulaModel_R1C1 = monaco.editor.createModel(value, LANGUAGE_ID_R1C1);

    formulaLanguageService_A1.widthLimit = this.props.widthLimit
    formulaLanguageService_A1.autoWidthLimit = this.props.autoWidthLimit
    formulaLanguageService_A1.formulaStyle = this.props.formulaStyle
    formulaLanguageService_A1.numberDecimalSeparator = this.props.numberDecimalSeparator
    formulaLanguageService_A1.displayLanguage = this.props.displayLanguage
    formulaLanguageService_A1.contentLanguage = this.props.contentLanguage

    formulaLanguageService_R1C1.widthLimit = this.props.widthLimit
    formulaLanguageService_R1C1.autoWidthLimit = this.props.autoWidthLimit
    formulaLanguageService_R1C1.formulaStyle = this.props.formulaStyle
    formulaLanguageService_R1C1.numberDecimalSeparator = this.props.numberDecimalSeparator
    formulaLanguageService_R1C1.displayLanguage = this.props.displayLanguage
    formulaLanguageService_R1C1.contentLanguage = this.props.contentLanguage

    this.editor = editor;
    if (this.props.formulaStyle === "A1") this.editor.setModel(this.monacoFormulaModel_A1)
    else this.editor.setModel(this.monacoFormulaModel_R1C1)
    this.forceUpdate();
    this.handleResize();
    // in case fix size didn't work 
    // this.editor.layout({ height: 500, width: window.innerWidth * 0.79 });

    // fix size 
    setTimeout(() => {
      this.handleResize();
    }, 1000);

    if (this.props.editorDidMount) {
      this.props.editorDidMount(editor, monaco);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.widthLimit !== this.props.widthLimit) {
      formulaLanguageService_A1.widthLimit = this.props.widthLimit;
      formulaLanguageService_R1C1.widthLimit = this.props.widthLimit;
    }
    if (prevProps.autoWidthLimit !== this.props.autoWidthLimit) {
      formulaLanguageService_A1.autoWidthLimit = this.props.autoWidthLimit
      formulaLanguageService_R1C1.autoWidthLimit = this.props.autoWidthLimit
    }
    if (prevProps.formulaStyle != this.props.formulaStyle) {
      formulaLanguageService_A1.formulaStyle = this.props.formulaStyle;
      formulaLanguageService_R1C1.formulaStyle = this.props.formulaStyle;
      if (this.props.formulaStyle === "A1") this.editor.setModel(this.monacoFormulaModel_A1)
      else this.editor.setModel(this.monacoFormulaModel_R1C1)
    }
    if (prevProps.numberDecimalSeparator !== this.props.numberDecimalSeparator) {
      formulaLanguageService_A1.numberDecimalSeparator = this.props.numberDecimalSeparator;
      formulaLanguageService_R1C1.numberDecimalSeparator = this.props.numberDecimalSeparator
    }
    if (prevProps.displayLanguage !== this.props.displayLanguage) {
      formulaLanguageService_A1.displayLanguage = this.props.displayLanguage;
      formulaLanguageService_R1C1.displayLanguage = this.props.displayLanguage
    }
    if (prevProps.contentLanguage !== this.props.contentLanguage) {
      formulaLanguageService_A1.contentLanguage = this.props.contentLanguage;
      formulaLanguageService_R1C1.contentLanguage = this.props.contentLanguage
    }
    if (this.props.preferences != prevProps.preferences) {
      if (this.diagnosticsAdapterA1)
        this.diagnosticsAdapterA1.setPreferences(this.props.preferences);
      if (this.diagnosticsAdapterR1C1)
        this.diagnosticsAdapterR1C1.setPreferences(this.props.preferences);
    }
    
    if ((this.props.enabled != prevProps.enabled) && !this.props.enabled) {
      this.editor.setSelection(new monaco.Selection(0, 0, 0, 0));
    }

    // Tie 20210908: what's the difference between componentDidUpdate and _handleEditorDidMount?
  }

  fallback() {
    return <div style={{
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 300,
      fontSize: 14,
      color: '#C0C0C0'
    }}><br /><br />&nbsp;&nbsp;&nbsp;&nbsp;... loading
    </div>;
  }

  shouldComponentUpdate(nextProps) {
    // Only update the component upon changes of the following values
    let compareValues = ['height', 'theme', 'value', 'onChange', 'editorWillMount', 'autoWidthLimit', 'widthLimit',
                            'optimizer', 'formulaStyle', 'numberDecimalSeparator', 'displayLanguage', 'contentLanguage', 'evaluateFormula',
                            'preferences', 'IsLoggingInOrOut']

    // if one property of the compareValues has changed then we update the component 
    return compareValues.map(key => this.props[key] !== nextProps[key]
      || this.props['options'].fontSize !== nextProps['options'].fontSize).filter(e => e).length != 0;
  }

  render() {
    console.log(`{...this.props}: ${JSON.stringify({ ...this.props })}`)
    let _handleEditorDidMount = this._handleEditorDidMount.bind(this)
    return <Suspense fallback={
      <div style={{
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        fontWeight: 300,
        fontSize: 14,
        color: '#C0C0C0'
      }}><br /><br />&nbsp;&nbsp;&nbsp;&nbsp;... loading
      </div>}>
      <MonacoEditor {...this.props} editorDidMount={_handleEditorDidMount} />
    </Suspense>
  }

  handleResize() {
    if (this.editor) {
      this.editor.layout();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }
}

export default connect((state) => ({
  preferences: getPreferences(state),
  IsLoggingInOrOut: getIsLoggingInOrOut(state)
}))(ResizableMonacoEditor);
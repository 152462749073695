import { integer } from "@codingame/monaco-languageclient";
import { AbstractSpreadsheetCommunicator } from "./abstract-spreadsheet-communicator";

export class ExcelDesktopSpreadsheetCommunicators extends AbstractSpreadsheetCommunicator {

    read_formula(formulaStyle: string) {
        return new Promise(async (res, rej) => {
            // @ts-ignore
            let value = await window.chrome.webview.hostObjects.control.GetExcelCurrentCell()
            res(value);
        });
    }

    read_numberDecimalSeparator () {
        return Promise.resolve("None")
    }

    read_displayLanguage() {
        return Promise.resolve("None")
    }

    read_contentLanguage() {
        return Promise.resolve("None")
    }

    evaluate_formula(value: string, formulaFull: string, startP: integer, formulaStyle: string) {
        return Promise.resolve("None")
    }

    write_formula(value: string, formulaStyle: string) {
        // @ts-ignore
        return window.chrome.webview.hostObjects.control.SetExcelCurrentCell(value)
    }


    postMessageInitialized = false;
    addOnSelectionChangeHandler(handler: any) {
        if (this.postMessageInitialized) return;
        this.postMessageInitialized = true;
        //@ts-ignore
        window.chrome.webview.addEventListener("message", (event) => {
            try {
                let data = event?.data;
                if (data?.type == "formula") {
                    handler();
                }
            } catch {

            }

        }, false);
    }

    removeSelectionChangeHandler() {
        return Promise.resolve("None")
    }

    hasNothingAbout(lib: string) {
        return Promise.resolve("None")        
    }

    addLibraryLatestVersion(lib: string) {
        return Promise.resolve("None")
    }

    addLibrary(lib: string) {
        return Promise.resolve("None")
    }

    currentVersionIdentical(lib: string, version: string) {
        return Promise.resolve("None")
    }

    removeLibrary(lib: string) {
        return Promise.resolve("None")
    }

    readVersion(lib: string) {
        return Promise.resolve("None")
    }

    modifyAFunction() {
        return Promise.resolve("None")        
    }

}
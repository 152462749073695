import axios, { AxiosError } from 'axios';
import * as OfficeHelpers from '@microsoft/office-js-helpers';

import { getSignToken } from './auth-utils';
import { registerAnonym } from './auth';

export interface KpiData {
  uid: string;
  app: string;
  url: string;
  token?: string;
  action?: any; //tslint:disable-line:no-any
}

////////// from factory users begin

export function userFromId (id, users) {
  for (var i = 0; i < users.length; i++)
      if (id === users[i]._id)
          return users[i];
  return null
}

// for the kpi page
// {to better}: should just extract key information
export function titleFromId (id, users) {
  var user = userFromId(id, users);
  var res = JSON.stringify(user);
  return res
}

// show ipInfo unlike auth.formalFromUserToken
export function displayNameFromId (id, users) {
  var user = userFromId(id, users)
  // console.log(id)
  if (user === null) { console.log(id); console.log(users); console.log("cannot possible"); return "cannot possible" } 
  if (user.local.type === "anonym") {
      let x;
      x = undefined;
      for (var i = 0; i < user.ipInfos.length; i++) {
          var ipInfo = user.ipInfos[i]; 
          if (ipInfo.city !== null && ipInfo.city !== undefined && ipInfo.city !== "")
              x = ipInfo.region + "-" + ipInfo.city
      }
      if (x === undefined) return "anonym"
      else return x
  }
  else if (user.currentName !== null && user.currentName !== undefined) return user.currentName
  else return user.local.id		
}

////////// from factory users end

const devs = {
  "Tie": {
      id_prod: "5a176fa24d96415280e22cc8",
      id_dev: "5a316f3805cbd3035b0a7326",
      names: ["Tie Cheng", "Cheng Tie", "chengtie"]
  },
  "Yi": {
      id_prod: "5a17a262e9b5bb602ae3c2aa"
  },
  "Nicolas": {
      id_prod: "5a2940d1cf76561801b733e7"
  },
  "Chuan": {
      id_prod: "5a25283fcf76561801b73289"
  }
}

const powerDeveloperEmails = [
  "chengtie@gmail.com",
  "liangyi19941213@gmail.com",
  "liang.yi@yahoo.com",
  "public.matrixlead@gmail.com",
  "ivarflying@gmail.com",
  "nicolas.dejean8@gmail.com"
  ]

var isPowerDeveloperEmail = function (email) {
  return powerDeveloperEmails.indexOf(email) !== -1
}

export function isLogUs (log) {
  for (let dev in devs)
      if (devs.hasOwnProperty(dev))
          if (devs[dev].id_prod === log.userId || devs[dev].id_dev === log.userId)
              return true
  return false
}

export function isUserUs (user) {
  for (let dev in devs)
      if (devs.hasOwnProperty(dev))
          if (devs[dev].id_prod === user._id || devs[dev].id_dev === user._id 
              || (devs[dev].names !== undefined && devs[dev].names.indexOf(user.currentName) !== -1))
              return true
  return false
}

export function isSubscriberUs (subscriber) {
  for (let i = 0; i < powerDeveloperEmails.length; i++)
      if (powerDeveloperEmails[i] === subscriber.mail)
          return true
  return false
}

// start and end are included in the result
// return an Object {"Fri Dec 01 2017 09:00:00 GMT+0100 (CET)":0,"Fri Dec 01 2017 10:00:00 GMT+0100 (CET)":0,...}
export function enumerateBetweenDates (start, end, type, value) {
    let abc = {}, step, s, e;
    switch (type) {
        case "hour": 
          step = 1;
          s = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate(), start.getUTCHours()));
          e = new Date(Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate(), end.getUTCHours()));
          break;
        case "day": 
          step = 24; 
          s = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate()));
          e = new Date(Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate()));
          break;
        case "week": 
          step = 7 * 24; 
          s = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate()));
          e = new Date(Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate()));
          break;
    }
    // https://stackoverflow.com/a/38050824/702977
    while (s.getTime() < e.getTime() + step * 60 * 60000) {
      if (value == "NEWSET")
        abc[s.getTime()] = new Set()
      else if (value == "EMPTYARRAY")
        abc[s.getTime()] = []
      else
        abc[s.getTime()] = value
      s = new Date(s.setUTCHours(s.getUTCHours() + step))
    }
    return abc
}

// input: {"Fri Dec 01 2017 09:00:00 GMT+0100 (CET)":0,"Fri Dec 01 2017 10:00:00 GMT+0100 (CET)":0,...}
// output: [[Fri Dec 01 2017 09:00:00 GMT+0100 (CET),0],[Fri Dec 01 2017 10:00:00 GMT+0100 (CET),0],...]
export function arrayFromObject (o) {
    var res = [] as any;
    for (var key in o) {
        if (o.hasOwnProperty(key) && !isNaN(o[key]))
            res.push([parseInt(key), o[key]])
    }
    return res
}

export function usersFromLogs (start, end, logs, appKind) {
  var res = new Set();
  for (var i = 0; i < logs.length; i++)
      if (start.getTime() <= (new Date(logs[i].date)).getTime() && (new Date(logs[i].date)).getTime() <= end.getTime() 
          && !isLogUs(logs[i])) {
          if ((appKind === "Funfun" && (logs[i].app === "FunfunSite" || logs[i].app === "FunfunExcelAddin")) 
              || (appKind === "Formatter" && (logs[i].app === "FormatterExcelAddin"))
              || (appKind === "any"))
              res.add(logs[i].userId)
          }
  return res
}

export function sessionsFromTimestamps (timestamps) {
  let sessions = [] as any;
  let start, end;
  let timestampsSorted = [...timestamps].sort(function (a, b) {
    return a - b
  }) 
  timestampsSorted.forEach(timestamp => {
    if (start === undefined && end === undefined) {
      start = new Date(timestamp);
      end = new Date(timestamp)
    } else if (end.getTime() + 3 * 60 * 60000 >= timestamp.getTime()) { //  three hours
      end = new Date(timestamp) // close enough, then we extend the end of the session
    } else { // case where end.getTime() + 30 * 60000 < timestamp.getTime() 
      sessions.push({start: start, end: end}); // very far, then we push a session
      start = new Date(timestamp);
      end = new Date(timestamp)
    }
  });

  if (!(start === undefined || end === undefined))
      sessions.push({start: start, end: end});

  return sessions
}

export function getUserBehaviors (logs, users) {
  let res = {}
  logs.forEach(function (log) {
    if (!res.hasOwnProperty(log.userId))
      res[log.userId] = {  
        logs: [log], timestamps: [new Date(log.date)] as any, sessions: [] }
    else {
      let behavior = res[log.userId]
      behavior.logs.push(log);
      behavior.timestamps.push(new Date(log.date));
    }
  })

  for (var userId in res) {
    let behavior = res[userId]
    behavior.sessions = sessionsFromTimestamps(behavior.timestamps);
  }
  return res
}

///// to delete
// input: a date and an Object {"Fri Dec 01 2017 09:00:00 GMT+0100 (CET)":0,"Fri Dec 01 2017 10:00:00 GMT+0100 (CET)":0,...}
// output: add one in all the intervals after that date
export function addOne (date, o) {
  for (var key in o) {
      if (o.hasOwnProperty(key) && !isNaN(o[key])) {
          if (date.getTime() < parseInt(key))
              o[key]++
      }
  }
}

////////// to delete
// input: a user ID, a start and an end time, all the logs
// {assumption}: logs are ordered by time
export function behaviorOfUser (userId, start, end, logs) {
  let behavior = { actions: [] as any, sessions: [], postIds: new Set(), timestamps: [] as any }
  for (let i = 0; i < logs.length; i++) {
      let log = logs[i]; 
      let date = new Date(log.date)
      if (log.userId === userId && start.getTime() <= date.getTime() && date.getTime() <= end.getTime()) {
          behavior.actions.push(log);
          behavior.timestamps.push(date);
          // if (log.action.postId !== null && log.action.postId !== undefined && posts.postFromId(logs[i].action.postId) !== null) {
          //     behavior.postIds.add(log.action.postId)
          // }
      }
  }
  behavior.sessions = sessionsFromTimestamps(behavior.timestamps);

  // behavior.postsHtml = "";
  // behavior.postIds.forEach(function (postId, value2, set) {
  //     behavior.postsHtml = behavior.postsHtml + '<a href="https://' + paramsDomain + '/edit/' + postId + '">' + posts.displayNameFromId(postId) + '</a>; '
  // })

  // behavior.nameHtml = "<a href='https://" + paramsDomain + '/userinfo/' + userId + "' title='" + users.titleFromId(userId) +  "'>" + users.displayNameFromId(userId) + '</a>'
  return behavior
} 

export async function post({ uid, token, app, url, action }: KpiData) {
  let sendLog = window.location.search !== "" ? (new URLSearchParams(window.location.search).get('send-log')) : (new URLSearchParams(window.location.hash).get('send-log'))
  if (sendLog !== null) localStorage.setItem("send-log", sendLog)
  if (localStorage.getItem("send-log") !== null) {
     if ((localStorage.getItem("send-log")!.toLowerCase() === "false") ||
        (localStorage.getItem("send-log")!.toLowerCase() === "no")) {     
      console.log("don't send log"); return null
    }
  }

  const data: any = {}; //tslint:disable-line:no-any
  data.app = app;
  data.url = url;

  let referral = window.location.search !== "" ? new URLSearchParams(window.location.search).get('referral') : new URLSearchParams(window.location.hash).get('referral')
  referral = (referral === null) ? "" : referral
  let referrals = localStorage.getItem("referrals") === null ? "" : JSON.parse(localStorage.getItem("referrals")!)
  referrals = (referrals === null) ? [] : referrals
  console.log("referral current: " + referral)
  console.log("referrals before: " + JSON.stringify(referrals))
  if (referral !== "" && referrals.indexOf(referral) === -1) referrals.push(referral)
  localStorage.setItem("referrals", JSON.stringify(referrals))
  console.log("referrals after: " + JSON.stringify(referrals))
  data.referrals = referrals

  data.env = {
    OfficeHelpersUtilitieshost: OfficeHelpers.Utilities.host,
    OfficeHelpersUtilitiesplatform: OfficeHelpers.Utilities.platform,
  };
  data.userId = uid;
  data.action = action;
  try {
    const res = await axios.get('https://ipinfo.io');
    data.cLoc = res.data;
  } catch (e) {
    const err: AxiosError<any> = e; //tslint:disable-line:no-any
    if (err.response) {
      data.cLoc = err.response.data;
    }
  }
  let signToken = await getSignToken();
  
  if(!signToken){
    await registerAnonym(); 
    signToken = await getSignToken(); 
  }
  const headers = { Authorization: 'Bearer ' + ( token || signToken) };
  // console.log(data)
  await axios.post(`${FUNFUN_URL}/httpOnly/kpi/addLog`, data, { headers, withCredentials: true });
}

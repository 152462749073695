
const rules = require("./rules.json").rules;

// {to better}: different field values should be prepared in one place (e.g., creating quickFix in OCaml; moving this file and rules.json to OCaml).
// It's not good to modify field values in different places 
export const infoFromResult = (r: any) => {
    if (r.kind === "Optimization") {
        let m = "`" + r.original.substring(1) + "` can be replaced by " + "`" + r.optimized + "`"
        let x: any = {};
        if (rules[r.rule]) {
            x = { ...rules[r.rule] };
            x.contentMarkdown = m + x.contentMarkdown + ` (${x.code})`;
            x.optimized = r.optimized;
            if (r.quickFix === "NA") 
                x.quickFix = "Replace " + r.original.substring(1) + " by " + r.optimized + `. (${x.code})`
            else
                x.quickFix = r.quickFix
        } else {
            x = { ...rules["default"] };
            x.optimized = "default optimized";
            if (r.quickFix === "NA") 
                x.quickFix = "default quickFix " + `. (${x.code})`
            else
                x.quickFix = r.quickFix
            x.diagnosticMessage = r.rule;
        }
        return x
    } else if (r.kind === "Error") {
        let x: any = {};
        x = { ...r };
        x.code = 0
        if ((x.quickFix === "NA") && (x.optimized !== "NA"))
            x.quickFix = "Replace " + r.original + " by " + r.optimized;
        else
            x.quickFix = r.quickFix
        return x
    } else {
        throw "an error from infoFromResult"
    }
}

// let message = `## Suggestion 
//  You can replace VLOOKUP with INEDX for better performance click the link for more information  \n
//  \n[Google](https://www.google.com) 
//  🔓🔓` ;
import { integer } from "@codingame/monaco-languageclient";
import { correctFloat } from "highcharts";
import context from "react-bootstrap/esm/AccordionContext";
import { setSyntheticLeadingComments } from "typescript";
import { AbstractSpreadsheetCommunicator } from "./abstract-spreadsheet-communicator";

const libFunctions = require("../excel-libraries/AutoXL-0.1.0.json");
export class ExcelWebSpreadsheetCommunicators extends AbstractSpreadsheetCommunicator {

    evaluate_formula(formula: string, formulaFull: string, startP: integer, formulaStyle: string) {
        function errorMsgProb(s: any) {
            if ((typeof s === "string") && (s.charAt(0) === '#') && ((s.slice(-1) === "!") || (s.slice(-1) === "?")))
                return true
            else 
                return false
        }

        async function deleteTmpSheet () {
            return Excel.run(async (context: any) => {
                var sheetCheck = context.workbook.worksheets.getItemOrNullObject("TmpEvaluationSheet");
                await context.sync() // must have
                if (!sheetCheck.isNullObject) {
                    sheetCheck.delete()
                    await context.sync() // must have
                }
            })
        }

        return Excel.run(async (context: any) => {
            async function findTable (selectedRange, tables) {
                // find the table that covers the selected range https://stackoverflow.com/a/39735631/702977
                var found = false;        
                for (var i = 0; i < tables.items.length; i++) {
                    try {
                        var table = tables.items[i];
                        var intersectionRange = table.getRange().getIntersection(selectedRange).load("address");
                        await context.sync();
        
                        // If reached past the sync, it means that "getIntersection" did not throw an error, and so the intersection must be valid.
                        found = true;
                        console.log(`Intersection found with table "${table.name}". ` + `Intersection range: "${intersectionRange.address}".`);
                        return table.name
                    } catch (e) {
                        var isExpectedError = e instanceof OfficeExtension.Error &&
                            (<OfficeExtension.Error>e).code === Excel.ErrorCodes.itemNotFound;
                        if (!isExpectedError) { throw e; } 
                    }
                }
                if (!found) { console.log("Selection does not intersect any table"); return "None" }
            }

            async function core (formula: string, formulaStyle: string, rowIndex: integer, columnIndex: integer) {
                var sheet = context.workbook.worksheets.add("TmpEvaluationSheet");
                sheet.visibility = Excel.SheetVisibility.hidden
                var range = sheet.getCell(rowIndex, columnIndex);
                if (formulaStyle === "A1") // similar as write_formula
                    range.formulasLocal = "=" + formula // should use [[ "=" + formula ]]
                else
                    range.formulasR1C1 = "=" + formula
                
                var spillRange = range.getSpillingToRangeOrNullObject()
                await context.sync()
                
                if (!spillRange.isNullObject) { range = spillRange }
                
                range.load("values"); await context.sync()
                sheet.delete(); await context.sync()
                return range.values
            }
         
            const sheetActive = context.workbook.worksheets.getActiveWorksheet();
            sheetActive.load("name");
            const selectedRange = context.workbook.getSelectedRange();
            selectedRange.load(["rowIndex", "columnIndex"]);
            var tables = sheetActive.tables.load("name");
            await context.sync()
            console.log("rowIndex", selectedRange.rowIndex)
            console.log("columnIndex", selectedRange.columnIndex)

            var table = await findTable(selectedRange, tables)
            console.log("table", table);
            
            const formulaNew = convertFormulaForEval(formula, sheetActive.name, table);
            console.log("outside formulaNew", formulaNew)
 
            var values 
            values = await core(formulaNew, formulaStyle, selectedRange.rowIndex, selectedRange.columnIndex).catch(await deleteTmpSheet)
            
            if ((values != undefined) && !errorMsgProb(values[0][0])) { 
                return values
            } else {
                const formulaWOWhitespacesLeft = formula.trimLeft();
                const shift = formula.indexOf(formulaWOWhitespacesLeft);
                const formulaFullWOWhitespacesLeft = formulaFull.trimLeft();
                var formulaFullWOWhitespacesLeftEqual;
                if (formulaFullWOWhitespacesLeft.charAt(0) === "=") 
                    formulaFullWOWhitespacesLeftEqual = formulaFullWOWhitespacesLeft.substring(1)
                else 
                    formulaFullWOWhitespacesLeftEqual = formulaFullWOWhitespacesLeft;
                const shiftMinus = formulaFull.indexOf(formulaFullWOWhitespacesLeftEqual);
                console.log("outside transformWithLet formulaWOWhitespacesLeft", formulaWOWhitespacesLeft);
                console.log("outside transformWithLet formulaFullWOWhitespacesLeftEqual", formulaFullWOWhitespacesLeftEqual);
                console.log("outside transformWithLet startP+shift-shiftMinus", startP + shift - shiftMinus);
                const formulaNewLet = transformWithLet(formula, formulaFullWOWhitespacesLeftEqual, startP + shift - shiftMinus)
                // const formulaNewLet = transformWithLet("LET(a,8,a)", "IF(2,\nLET(a,3,a),\n        LET(a,\n\n8,a))", 4)
                // const formulaNewLet = transformWithLet("nr*nc", "LET(array,B6:D11,nr,ROWS(array),nc,COLUMNS(array),z,SEQUENCE(nr*nc)-1,r,INDEX(array,1+INT(z/nc),1+MOD(z,nc)),r)",1)
                console.log("outside transformWithLet formulaNewLet", formulaNewLet)
                const formulaNewLetNew = convertFormulaForEval(formulaNewLet, sheetActive.name, table);
                console.log("outside formulaNewLetNew", formulaNewLetNew)
                values = await core(formulaNewLetNew, formulaStyle, selectedRange.rowIndex, selectedRange.columnIndex)

                return values
            }
        }).catch(
            deleteTmpSheet
            // unprotect workbook. For a better efficiency, check protection only when the first try fails
            // context.workbook.protection.unprotect();
            // await context.sync()
        )
    }

    evaluate_formula_names (formula: string) {
        return Excel.run(async (ctx: any) => {

            const sheet = ctx.workbook.worksheets.getActiveWorksheet();
            // const sheet = ctx.workbook.worksheets.getLast();
            sheet.load("name");

            const nameTmp1 = ctx.workbook.worksheets.getActiveWorksheet().names.getItemOrNullObject("FormulaEditorEvalName");
            nameTmp1.delete()
            const nameTmp2 = ctx.workbook.worksheets.getLast().names.getItemOrNullObject("FormulaEditorEvalName");
            nameTmp2.delete()
            const nameTmp3 = ctx.workbook.names.getItemOrNullObject("FormulaEditorEvalNameWorkbook");
            nameTmp3.delete()
            await ctx.sync()

            console.log("sheet.name", sheet.name)
            // const formulaNew = convertFormulaForEval(formula, sheet.name);
            // const formulaNew = 'IF(ROW(A1)>0,INDEX(Sheet2!$B$3:$B$7,3))' // works
            // const formulaNew = 'CHOOSE(ROW(A1)>0,INDEX(Sheet2!$B$3:$B$7,3))' // works
            // const formulaNew = "IF(ROW(Sheet1!$A$1)>0,Sheet1!$B$3+2)" // works
            // const formulaNew = 'IF(ROW(Sheet1!$A$1)>0,INDEX(Sheet2!$B$3:$B$7,{3,4}))' // using name does not calculate well, even in Name Manager
            // const formulaNew = 'IF(ROW(Sheet1!$A$1)>0,OFFSET(Sheet1!$B$3,2,0,2))' // works, return array
            // const formulaNew = "LET(x,INDEX(Sheet2!$B$3:$B$7,{3,4}),if(column(x)>0,x))" // does not work well
            // const formulaNew = 'LET(t,INDEX(Sheet1!$B$3:$C$7,{3;4},{1,2}),trues,INDEX({TRUE},SEQUENCE(ROWS(t),COLUMNS(t),1,0)),IF(trues,t))' // does not work, return null
            // const formulaNew = 'LET(t,INDEX(Sheet1!$B$3:$C$7,{3;4},{1,2}),trues,{TRUE,TRUE;TRUE,TRUE},IF(trues,t))' // same value for the whole table, does not work as well as Name Manager
            // const formulaNew = 'INDEX({1,2,3;4,5,6},2,1)' // works
            // const formulaNew = 'LET(x,INDEX({1,2,3;4,5,6},{1;2},1),ROWS(x))' // not correct, so problem of using Excel JavaScript api names
            // const formulaNew = "IF(ROW(A1)>0, XLOOKUP(2,Sheet1!$B$3:$B$7,Sheet1!$B$3:$B$7))" // works
            // const formulaNew = "XLOOKUP({2;3},Sheet1!$B$3:$B$7,Sheet1!$B$3:$B$7)" // does not work, return null
            // const formulaNew = "IF(ROW(A1)>0, XLOOKUP({2;3},Sheet1!$B$3:$B$7,Sheet1!$B$3:$B$7))" // does not work well, return single value 2
            const formulaNew = 'Sheet1!B3:B7+2' // works
            // const formulaNew = 'INDEX(Sheet1!$B$3:$B$7,{3;4})' // does not work, return null
            // const formulaNew = '0+INDEX(Sheet2!$B$3:$B$7,{3,4})' // does not work, return single value 30
            // const formulaNew = 'INDEX(Sheet2!$B$3:$B$7,{3,4})' // does not work, return null
            // const formulaNew = 'OFFSET(Sheet1!$B$3,2,0,2)' // does not work, return null
            // add a non-existing name

            // sheet.names.add("FormulaEditorEvalName", "=" + formulaNew);  // worksheet level
            ctx.workbook.names.add("FormulaEditorEvalNameWorkbook", "=" + formulaNew);
            // ctx.workbook.names.addFormulaLocal("FormulaEditorEvalNameWorkbook", "=" + formulaNew);
            await ctx.sync();
            
            // const nameTmp = sheet.names.getItemOrNullObject("FormulaEditorEvalName");  // worksheet level
            const nameTmp = ctx.workbook.names.getItemOrNullObject("FormulaEditorEvalNameWorkbook");

            const avs = nameTmp.arrayValues.load();
            nameTmp.load();
            await ctx.sync();
            console.log("logstie nameTmp.value", nameTmp.value)
            // const vaj = nameTmp.valueAsJson.load();
            // nameTmp.load()
            await ctx.sync();         

            console.log("logstie before convertFormulaForEval", formula);
            console.log("logstie after convertFormulaForEval", formulaNew);   
            console.log("logstie Evaluated value:", avs.values)
            // console.log("logstie vaj", vaj)
            console.log("logstie", JSON.stringify(nameTmp.toJSON()))

            var namedItems = ctx.workbook.worksheets.getActiveWorksheet().names.load();
            var x = ctx.workbook.worksheets.getActiveWorksheet()
            // var namedItems = ctx.workbook.worksheets.getLast().names.load();
            // var x = ctx.workbook.worksheets.getLast()
            x.load("name");
            await ctx.sync();
            console.log("logstie sheet.name", x.name);
            console.log("logstie This worksheet contains " + namedItems.items.length + " named items.");
            for (let i = 0; i < namedItems.items.length; i++) {
              console.log("logstie", JSON.stringify(namedItems.items[i])+"\n");
            }

            var namedItems = ctx.workbook.names.load();
            await ctx.sync();
            console.log("logstie This workbook contains " + namedItems.items.length + " named items.");
            for (let i = 0; i < namedItems.items.length; i++) {
              console.log("logstie", JSON.stringify(namedItems.items[i])+"\n");
            }
            await ctx.sync();

            return avs.values
        })
    }

    read_formula(formulaStyle: string) {
        return Excel.run(async (ctx: any) => {
            if (formulaStyle === "A1") {
                // https://docs.microsoft.com/en-us/javascript/api/excel/excel.range?view=excel-js-preview#formulasLocal
                // Represents the formula in A1-style notation, in the user's language and number-formatting locale.
                // For example, the English "=SUM(A1, 1.5)" formula would become "=SUMME(A1; 1,5)" in German.
                // If a cell has no formula, its value is returned instead.
                const selectedRange = ctx.workbook.getSelectedRange();
                selectedRange.load(['formulasLocal']);
                await ctx.sync();
                let x = selectedRange.formulasLocal[0][0]; 
                // console.log("read_formula: ");
                // console.log(x);
                return x;
            } else {
                // https://docs.microsoft.com/en-us/javascript/api/excel/excel.range?view=excel-js-preview#formulasR1C1
                // Represents the formula in R1C1-style notation. If a cell has no formula, its value is returned instead.
                const selectedRange = ctx.workbook.getSelectedRange();
                selectedRange.load(['formulasR1C1']);
                await ctx.sync();
                let x = selectedRange.formulasR1C1[0][0];
                return x;
            }
                 // formulas: Represents the formula in A1-style notation. If a cell has no formula, its value is returned instead.
        });
    }

    write_formula(value: string, formulaStyle: string) {
        return Excel.run(async (ctx: any) => {
            var selectedRange = ctx.workbook.getSelectedRange();
            if (formulaStyle === "A1")
                selectedRange.formulasLocal = value
            else
                selectedRange.formulasR1C1 = value
            await ctx.sync();
        })
    }

    // https://docs.microsoft.com/en-us/javascript/api/excel/excel.numberformatinfo?view=excel-js-preview#numberDecimalSeparator
    read_numberDecimalSeparator () {
        return Excel.run(async (ctx: any) => {
            ctx.application.cultureInfo.numberFormat.load("numberDecimalSeparator");
            await ctx.sync();
            return ctx.application.cultureInfo.numberFormat.numberDecimalSeparator;
        });
    }

    // https://docs.microsoft.com/en-us/javascript/api/office/office.context?view=excel-js-preview#displayLanguage
    read_displayLanguage() {
        return Office.context.displayLanguage
    }

    // https://docs.microsoft.com/en-us/javascript/api/office/office.context?view=excel-js-preview#contentLanguage
    read_contentLanguage() {
        return Office.context.contentLanguage
    }
 
    addOnSelectionChangeHandler(handler: any) {
        Office.context.document.addHandlerAsync(
            Office.EventType.DocumentSelectionChanged,
            handler,
        );
    }

    removeSelectionChangeHandler() {
        Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, {}, function(result){});
    }

    print_all_workbook_names() {
        return Excel.run(async (ctx: any) => {
            var namedItems = ctx.workbook.names.load();
            await ctx.sync();
            console.log("This workbook contains " + namedItems.items.length + " named items.");
            for (let i = 0; i < namedItems.items.length; i++) {
              console.log(JSON.stringify(namedItems.items[i])+"\n");
            }
            return null
        })
    }

    delete_all_workbook_names() {
        return Excel.run(async (ctx: any) => {
            var namedItems = ctx.workbook.names;
            var namesToDelete = ["try", "tryagain", "tryagainagain"];
            for (var i = 0; i < namesToDelete.length; i++) { 
                const x = namedItems.getItemOrNullObject(namesToDelete[i]);
                x.delete()
            }       
            await ctx.sync();
            return null
        })
    }

    // {can better}: make a list of functions that AutoXL has ever published in different versions
    hasNothingAbout(lib: string) {
        return Excel.run(async (ctx: any) => {
            var namedItems = ctx.workbook.names.load();
            await ctx.sync();
            console.log("namedItems.items", namedItems.items)
            for (var i = 0; i < libFunctions.length; i++) {
                for (var j = 0; j < namedItems.items.length; j++) {
                    if (libFunctions[i].name === namedItems.items[j].name) {
                        console.log("hasNothingAbout: false")
                        return false
                    }
                }
            };
            console.log("hasNothingAbout: true")
            return true
        }
    )}

    readVersion(lib: string) {
        return Excel.run(async (ctx: any) => {
            const f = ctx.workbook.names.getItemOrNullObject("A.VERSION");
            f.load();
            await ctx.sync();
            console.log("readVersion", f)
            if (f === null) {
                return "Unknown"
            } else if (f.formula === '= LAMBDA(\"AutoXL 0.1.0\")') {
                return "0.1.0"
            } else return f.formula
        }
    )}

    // {can better}: make a list of functions that AutoXL has ever published in different versions
    removeLibrary(lib: string) {
        return Excel.run(async (ctx: any) => {
            ctx.application.load('calculationMode');
            await ctx.sync();
            const savedMode = ctx.application.calculationMode;
            ctx.application.calculationMode = Excel.CalculationMode.manual;

            await ctx.sync();
            const names = ctx.workbook.names
            for (var i = 0; i < libFunctions.length; i++) {
                const f = names.getItemOrNullObject(libFunctions[i].name);
                f.delete()
            }
            await ctx.sync();

            ctx.application.calculationMode = savedMode
            await ctx.sync()
            console.log("removed the whole library")
        })
    }

    addLibrary(lib: string, version: string) {
        return Excel.run(async (ctx: any) => {
            ctx.application.load('calculationMode');
            await ctx.sync();
            const savedMode = ctx.application.calculationMode;
            ctx.application.calculationMode = Excel.CalculationMode.manual;

            for (var i = 0; i < libFunctions.length; i++) {
                const f = ctx.workbook.names.getItemOrNullObject(libFunctions[i].name);
                f.delete()
                ctx.workbook.names.add(libFunctions[i].name, libFunctions[i].formula);
            }
            await ctx.sync();

            ctx.application.calculationMode = savedMode
            await ctx.sync();

            this.print_all_workbook_names();
            return version
        })
    }

    addLibraryLatestVersion(lib: string) {
        console.log("addLibraryLatestVersion inside communicator")
        return this.addLibrary(lib, "0.1.0")
    }

    currentVersionIdentical(lib: string, version: string) {
        return Excel.run(async (ctx: any) => {
            var namedItems = ctx.workbook.names.load();
            await ctx.sync();
            var libFunctionsWW : string[] = [];
            for (var i = 0; i < libFunctions.length; i++) { libFunctionsWW.push(libFunctions[i].formula.replace(/\s/g, "")) }
            var namedItemsWW : string[] = [];
            for (var j = 0; j < namedItems.items.length; j++) { namedItemsWW.push(namedItems.items[j].formula.replace(/\s/g, "")) }
            console.log("namedItems.items", namedItems.items)
            for (var i = 0; i < libFunctions.length; i++) {
                var found = false;
                for (var j = 0; j < namedItems.items.length; j++) {
                    if (libFunctions[i].name === namedItems.items[j].name) {
                        found = true;
                        if (libFunctionsWW[i] !== namedItemsWW[j]) {
                            console.log(libFunctionsWW[i]);
                            console.log(namedItemsWW[j])
                            return false
                        }
                    }
                }
                if (!found) return false
            };
            return true
        }
    )}

    modifyAFunction() {
        return Excel.run(async (ctx: any) => {
            console.log("modifyAFunction inside communicator");
            return Excel.run(async (ctx: any) => {
                for (var i = 0; i < libFunctions.length - 1; i++) {
                    const f = ctx.workbook.names.getItemOrNullObject(libFunctions[i].name);
                    f.delete()
                    ctx.workbook.names.add(libFunctions[i].name, "hahaha");
                }
                await ctx.sync();
                this.print_all_workbook_names();
            })
        }
    )}

}

import React, { ReactNode } from 'react';
import { connect } from 'dva';

import LoginForm from '../LoginForm';
import RegisterForm from '../RegisterForm';
import { State as AuthState } from '../../models/auth';
import { State } from '../../store/reducer';
import * as authServices from '../../services/auth';
import { Dispatch } from 'redux';
import { getIsLoggedIn } from '../../selectors/auth';
import { push } from 'react-router-redux';
import selectors from '../../selectors';
import { AppName } from '../../models/app';
interface LoginPageProps extends AuthState {
  isLoggedIn: boolean;
  dispatch: Dispatch<{ type: string; payload?: any }>;
  app?: string;
}

class LoginPage extends React.Component<LoginPageProps> {
  children: ReactNode;

  login = (payload: authServices.LoginPayload) => {
    this.props.dispatch({
      type: 'auth/login',
      payload,
    });
  };

  loginSSO = async () => {
    this.props.dispatch({
      type: 'auth/loginSSO'
    });

  }
  loginOauth = (payload: authServices.LoginOauthPayload) => {
    this.props.dispatch({
      type: 'auth/loginOauth',
      payload,
    });
  };

  logout = () => {
    this.props.dispatch({
      type: 'auth/logout',
      payload: { },
    });
  };

  redirect = (path: string) => {
    this.props.dispatch(push(path));
    // console.log("window.location.href: ");
    // console.log(path);
    // window.location.href = `https://v4.10studio.tech/#${path}` // tried to solve https://github.com/chengtie/10Studio/issues/256#issuecomment-972129152, but it did not work well.
  };

  render() {
    const user = this.props.user;

    const items: any[] = [];

    if (!this.props.IsLoggingInOrOut && this.props.isLoggedIn && this.props.next) {
      this.redirect(this.props.next || "");
      return <></>;
    }
    if (this.props.app == AppName.PrettyFormula) {
      items.push(
        <div key="loginForm-description">
          <div className="login-form-description app-description-wrapper">
            {/* <h3 className="border-label"> Pretty Formula </h3> */}
            <p className="app-description">
              <span className="app-description-title">Pretty Formula</span><br />
              formats long and complex formulas<br />
              &nbsp; &nbsp;  to make them easy to understand <br />
              &nbsp; &nbsp; &nbsp; &nbsp; to make Excel life pretty.
            </p>
            {/* <h5>  Please sign in to continue </h5> */}
          </div>
        </div>);
    }
    if (!this.props.isLoggedIn) {

      items.push(
        <div key="loginForm">
         
          <br />
          <LoginForm
            error={this.props.loginErrorMsg}
            ssoLoginError ={this.props.ssoLoginError}
            login={this.login}
            loginOauth={this.loginOauth}
            loginSSO={this.loginSSO.bind(this)}
          />
          <br />
          <RegisterForm />
          <br />
        </div>,
      );
    }

    items.push(
      <div key="privacy-statement">
        <h5 className="privacy-description mb-5">
          By signing up for and by signing in to this website you accept our: <a href="https://www.10studio.tech/docs/privacy/" target="_blank">Privacy policy</a> and <a href="https://www.10studio.tech/docs/terms/" target="_blank">Terms</a>.
          <br /> <br />
        </h5>
      </div>
    )
    return (
      <div className="container">
        <div className="row">
          <div className="col text-center">{items}</div>
        </div>
      </div>
    );
  }
}

export default connect((state: State) => ({
  ...state.auth, isLoggedIn: getIsLoggedIn(state), app: selectors.app.selectAppName(state)
}))(
  LoginPage,
);

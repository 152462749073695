import { integer } from "@codingame/monaco-languageclient";

export interface ISpreadsheetCommunicator {
    /**
     * Get value from currently select cell 
    */
    evaluate_formula(value: string, formulaFull: string, startP: integer, formulaStyle: string);
    read_formula(formulaStyle: string);
    read_numberDecimalSeparator();
    read_displayLanguage();
    read_contentLanguage();

    /**
    * Write value to currently selected cell
    * @param value
    * @param formulaStyle 
    */
    write_formula(value: string, formulaStyle: string);

    /**
     * 
     * @param handler 
     * @returns true if added 
     */
    addOnSelectionChangeHandler(handler);
    removeSelectionChangeHandler();
    
    hasNothingAbout(lib);
    addLibraryLatestVersion(lib);
    addLibrary(lib, version);
    currentVersionIdentical(lib, version);
    removeLibrary(lib);
    readVersion(lib);
    modifyAFunction();
}
export abstract class AbstractSpreadsheetCommunicator implements ISpreadsheetCommunicator {
    isWriting: boolean = false;
    isReading: boolean = false;
    lastSelection = { row: -1, column: -1 };
    abstract evaluate_formula(value: string, formulaFull: string, startP: integer, formulaStyle: string);
    abstract read_formula(formulaStyle: string);
    abstract read_numberDecimalSeparator();
    abstract read_displayLanguage();
    abstract read_contentLanguage();
    abstract write_formula(value: string, formulaStyle: string);
    abstract addOnSelectionChangeHandler(handler);
    abstract removeSelectionChangeHandler();
    
    abstract hasNothingAbout(lib: string);
    abstract addLibraryLatestVersion(lib: string);
    abstract addLibrary(lib: any, version: any);
    abstract currentVersionIdentical(lib: string, version: string)
    abstract removeLibrary(lib: string);
    abstract readVersion(lib: string);
    abstract modifyAFunction()
}
/**
 * title: 10 Studio -- Welcome
 * Routes:
 *   - ./src/routes/LoginRoute
 *   - ./src/routes/SubscriptionRoute
 **/

import React from 'react';
import { withRouter , RouteComponentProps} from 'react-router';
import queryString from 'query-string';
import { PrimaryButton, ActionButton } from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import  styles from './index.module.scss';
import axios from 'axios';

import { getPlatform, PlatformType } from '../../platform';

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  itemStack: {
    childrenGap: 10,
  },
  headingStack: {
    childrenGap: 5,
  },
};

const sampeSheetNameFormulaEditor = '10StudioFormulaEditorSample';
const formulasFormulaEditor = [
  '=SUMPRODUCT(AH7:AL7,$AH$5:$AL$5)/AM7+(102/151/12)',
  '=IF(E7=0,0,VLOOKUP($E7,Donnees_Chiffrage,4,FALSE))*(D7)*(26+AO7/12+AR7/12)',
  '=SUMPRODUCT(OFFSET($K$3,0,0,SUM(ROW())-1),OFFSET($P$3,11-SUM(ROW()),0,SUM(ROW())-1))+SUMPRODUCT(OFFSET($K$3,0,0,SUM(ROW())-1),OFFSET($P$3,11-SUM(ROW()),0,SUM(ROW())-1))',
  '=ROUNDUP(IF(T6 <> "ü", 0, SUMIF(K4:K5001, S6, G4:G5001))+IF(T7 <> "ü", 0, SUMIF(K4:K5001, S7, G4:G5001))+IF(T8 <> "ü", 0, SUMIF(K4:K5001, S8, G4:G5001))+IF(T9 <> "ü", 0, SUMIF(K4:K5001, S9, G4:G5001))+IF(T10 <> "ü", 0, SUMIF(K4:K5001, S10, G4:G5001)) / 1440,0)',
  '=IF(AA104="Agree",Z104,IF(AA104="Disagree - Low",1,IF(AA104="Disagree - Medium",3,5)))',
  '=IF(G35="high",D35+2,IF(G35="medium",D35+1,D35))',
  '=STDEV(OFFSET(BH31,1,0)-OFFSET(BH31,2,0))*100',
  // '=VLOOKUP($C16,\'IMS ATC4 data\'!$A$7:$G$489,4,FALSE)',
  '=IF(H35="--",RANDBETWEEN(7,16),H35)',
  '=IF(AJ105="--","low",IF(AJ105>$AK$4,"high",IF(AJ105>$AK$5,"medium","low")))',
  '=(SUMPRODUCT(BT10:BU10,$BT$5,$BU$5)/(SUM(BO10:BQ10)/12)*1)*(1+IF(AO10=52,2,2)*BP10/SUM(BO10:BQ10)+5*BQ10/SUM(BO10:BQ10))+(102/151/12)',
  '=IF(SEARCH("ABC",H46)>0,IF((DATE(((2014+IF(VALUE(MID(H46,(SEARCH("/",H46))-2,2))=1,1,0))),(MID(H46,(SEARCH("/",H46))-2,2)),(MID(H46,(SEARCH("/",H46))+1,2))))<Sheet1!B11,"Send","Don\'t"),"#VALUE!")',
  '=LEFT(E102,FIND(" ",E102))&" "&$D$4&" "&"INDEX"',
  '=VLOOKUP(CN27,$H$485:$X$496,2,FALSE)-VLOOKUP(CN28,$H$485:$X$496,2,FALSE)',
  '="Position change between "&TEXT(C5,"DD/MMM/YY")&"-"&TEXT(C4,"DD/MMM/YY")',
  '="Rate chg between "&TEXT(C5,"dd/mmm/yy")&"-"&TEXT(C4,"dd/mmm/yy")',
  '="FX fwd implied vs onshore basis as of "&TEXT(C4,"dd/mmm/yy")',
  '=AB288&" cumulative pick up as of "&TEXT($C$5,"DD/MMM/YY")',
  '="NDF implied vs onshore basis as of "&TEXT(C5,"dd/mmm/yy")',
  // '=(M11-VLOOKUP(G11,\'USD LIbor\'!$B$9:$N$16,8,FALSE))*100',
  '=IF(ROWS(CJ$7:CJ239)>MAX($CI$7:$CI$302),"",LOOKUP(ROWS(CJ$7:CJ239),($CI$7:$CI$302),$CH$7:$CH$302))',
  // '=IF(CC143="Non demandé pour ce magasin","CV0",IF(CC143<\'Données Chiffrage\'!A$52,\'Données Chiffrage\'!B$52,IF(CC143<\'Données Chiffrage\'!A$53,\'Données Chiffrage\'!B$53,IF(CC143<\'Données Chiffrage\'!A$54,\'Données Chiffrage\'!B$54,IF(CC143<\'Données Chiffrage\'!A$55,\'Données Chiffrage\'!B$55,IF(CC143<\'Données Chiffrage\'!A$56,\'Données Chiffrage\'!B$56,IF(CC143<\'Données Chiffrage\'!A$57,\'Données Chiffrage\'!B$57,IF(CC143<\'Données Chiffrage\'!A$58,\'Données Chiffrage\'!B$58,IF(CC143<\'Données Chiffrage\'!A$59,\'Données Chiffrage\'!B$59,0)))))))))',
  // '=IF(BO367=0,"EQC1",VLOOKUP(BO367,\'Données Chiffrage\'!$B$41:$L$50,2,FALSE))',
  '=(CO292*CN292)+(CA292*BZ292)+(BF292*BE292)',
  '=IF(CA255>0,(CA255-BY255)/CA255,0)',
  '=IFERROR((CO405-CM405)/CO405,0)',
  '=+X4818*IF(AND(O4818="Avant ouverture",J4818="SDV"),VLOOKUP($B4816,Hyper_Etude,4,FALSE),1)',
  '=IF(Z1735>0,T1735/Z1735*AC1735/12,0)',
  '=IF(T847>0,VLOOKUP(I847,Hyper_Cadencier,4,FALSE),0)',
];

const sampeSheetNameVerificator = '10StudioVerificatorSample';
const formulasVerificator = [
  '="abc"+123',
  '',
  '=TODAY()',
  '',
  '=TODAY()*2',
  '',
  '="abc">123',
  '',
  '="1">123',
  '',
  '=TRUE>123',
  '',
  '=FALSE>123',
];

const readExcelSheetFormulas = async (
  url: string,
  sheet?: string,
): Promise<{ formulas: string[][]; range: string }> => {
  if (url.indexOf('://') === -1) {
    // const domain =
    //   window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
    const domain = 'https://www.10studio.tech';
    url = domain + (url[0] === '/' ? url : '/' + url);
  }
  console.log(url);
  const resp = await axios.post(`${FUNFUN_URL}/httpOnly/parseExcelSheet`, {
    url,
    sheet,
  });
  return resp.data;
};

interface IState {
  yOverflow: boolean;
}

class Welcome extends React.Component<RouteComponentProps<any>, IState> {
  readonly state = {
    yOverflow: false,
  };

  componentDidMount() {
    
    const PageHeight = window.innerHeight;
    const mainContent = document.getElementById('main-content');
    const mainContentHeight = mainContent ? mainContent.clientHeight + 40 : 0;
    console.log(mainContentHeight, PageHeight);
    if (PageHeight < mainContentHeight) {
      this.setState({ yOverflow: true });
    }
  }

  createFormulaEditorSample = async () => {
    const url = '/files/10Studio-Sample-EN.xlsx';
    // const sheetName = 'Pretty Formula';
    const sheetName="Formula Editor"
    try {
      const { formulas, range: rg } = await readExcelSheetFormulas(url, sheetName);
      await Excel.run(async context => {
        context.workbook.worksheets.getItemOrNullObject(sampeSheetNameFormulaEditor).delete();
        let sheet = context.workbook.worksheets.add(sampeSheetNameFormulaEditor);
        sheet.activate();
        await context.sync();
        const range = sheet.getRange(rg);
        range.formulas = formulas;
        await context.sync();
        sheet = context.workbook.worksheets.getItem(sampeSheetNameFormulaEditor);
        sheet.activate();
        await context.sync();
      })
        .then(() => this.props.history.push('/10studio-editor'))
        .catch(err => {
          // tslint:disable-next-line: no-console
          console.log(err);
        });
    } catch (e) {      
      // tslint:disable-next-line: no-console
      console.log('fetch example data error, please retry: ', e);
    }
  };

  createVerificatorSample = async () => {
    await Excel.run(async context => {
      context.workbook.worksheets.getItemOrNullObject(sampeSheetNameVerificator).delete();
      let sheet = context.workbook.worksheets.add(sampeSheetNameVerificator);
      sheet.activate();
      await context.sync();
      const range = sheet.getRange(`B4:B${4 + formulasVerificator.length - 1}`);
      range.formulas = formulasVerificator.map(x => [x]);
      await context.sync();
      sheet = context.workbook.worksheets.getItem(sampeSheetNameVerificator);
      sheet.activate();
      await context.sync();
    })
      .then(() => this.props.history.push('/spreadsheet-verificator'))
      .catch(err => {
        // tslint:disable-next-line: no-console
        console.log(err);
      });
  };

  createOptimizerSample = async () => {
    const url = '/files/test-optimizer.xlsx';
    const sampeSheetNameOptimizer = '10StudioOptimizerSample';
    try {
      const { formulas, range: rg } = await readExcelSheetFormulas(url);
      await Excel.run(async context => {
        context.workbook.worksheets.getItemOrNullObject(sampeSheetNameOptimizer).delete();
        let sheet = context.workbook.worksheets.add(sampeSheetNameOptimizer);
        sheet.activate();
        await context.sync();
        const range = sheet.getRange(rg);
        range.formulas = formulas;
        await context.sync();
        sheet = context.workbook.worksheets.getItem(sampeSheetNameOptimizer);
        sheet.activate();
        await context.sync();
      })
        .then(() => this.props.history.push('/spreadsheet-optimizer'))
        .catch(err => {
          // tslint:disable-next-line: no-console
          console.log(err);
        });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log('fetch example data error, please retry: ', e);
    }
  };

  _next: string | undefined = undefined;

  getNext() {
    if (this._next !== undefined) {
      return this._next;
    }
    const query = queryString.parse(this.props.location.search);
    let next = '';
    if (query.next) {
      next = Array.isArray(query.next) ? query.next[0] : query.next;
    } else {
      return '';
    }
    if (next.length === 0 || next[0] != '/') {
      next = '/' + next;
    }
    this._next = next;
    return next;
  }

  getAddinOrder = (name: string, index: number): number => {
    const next = this.getNext().slice(1);
    if (next === name) {
      return 1;
    } else {
      return index + 2;
    }
  };

  getAddinOrders = (names: string[]): number[] => {
    return names.map((v, i) => this.getAddinOrder(v, i));
  };

  clickOpenChat = () => {
    //window.location.href = '/helpdesk.html';
    // window.open("https://www.10studio.tech/docs/helpdeskAndIssues");
    window.open("https://www.10studio.tech/help")
  };

  goToFunfun = (path: string) => {
    window.location.href = `${FUNFUN_PROD_URL}/1/#/${path}`;
  };

  render() {
    const next = this.getNext();
    if (next.startsWith('/funfun-home')) {
      this.goToFunfun('addin/homePlus');
      return <></>;
    }
    if (next.startsWith('/funfun-preview')) {
      this.goToFunfun('addin/preview');
      return <></>;
    }

    if (next !== '') {
      window.localStorage.setItem('visited10Studio', 'true');
      if (window.localStorage.getItem('visited10Studio')) {
        this.props.history.push(next);
        return <></>;
      } else {
        window.localStorage.setItem('visited10Studio', 'true');
      }
    }

    const orders = this.getAddinOrders([
      'formulaEditor',
      'verificator',
      'bottleneckDetector',
      'optimizer',
    ]);

    const extraStyle: React.CSSProperties = this.state.yOverflow
      ? {
          position: 'absolute',
          paddingRight: {
            [PlatformType.PC]: '20px',
            [PlatformType.Mac]: '40px',
            [PlatformType.OfficeOnline]: '0px',
            [PlatformType.iOS]: '20px',
            [PlatformType.Android]: '20px',
            [PlatformType.Universal]: '20px',
          }[getPlatform()],
        }
      : {};

    const titleFontSize = 'large';
    const contentFontSize = 'mediumPlus';

    return (
      <>
        <div
          id="main-content"
          style={{ width: '100%', maxWidth: 480, minWidth: 320, ...extraStyle }}
        >
          <Stack className={styles.container}>
            {/* <Header><Text variant={"xxLarge"}> Welcome To 10 Studio!</Text></Header> */}
            <Stack.Item order={orders[0]} className={styles.addinItem}>
              <Stack tokens={tokens.sectionStack}>
                <Stack tokens={tokens.headingStack}>
                  <div>
                    {/* <ActionButton
                      style={{ float: 'right', height: 30, fontSize: 14, width: "100%" }}
                      iconProps={{ iconName: 'ChatInviteFriend' }}
                      onClick={this.clickOpenChat}
                    >
                      Contact Us
                    </ActionButton> */}
                    <div style={{ clear: 'both', display: 'inline' }} />
                    <Text className={styles.titleText} variant={titleFontSize}>
                      Formula Editor
                    </Text>
                  </div>
                  <Text variant={contentFontSize} block={true}>
                    Better read and understand long and complex formulas.
                  </Text>
                </Stack>
                <Stack tokens={tokens.itemStack}>
                  <PrimaryButton
                    className={styles.button}
                    allowDisabledFocus={true}
                    text="Formula Editor"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.props.history.push('/10studio-editor')}
                  />
                  <PrimaryButton
                    className={styles.button}
                    allowDisabledFocus={true}
                    text="Formula Editor & sample data"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={this.createFormulaEditorSample}
                  />
                </Stack>
              </Stack>
            </Stack.Item>
            <Stack.Item order={orders[1]} className={styles.addinItem}>
              <Stack tokens={tokens.sectionStack}>
                <Stack tokens={tokens.headingStack}>
                  <Text className={styles.titleText} variant={titleFontSize}>
                    Spreadsheet Verificator
                  </Text>
                  <Text variant={contentFontSize} block={true}>
                    Verify and detect errors in your spreadsheets.
                  </Text>
                </Stack>
                <Stack tokens={tokens.itemStack}>
                  <PrimaryButton
                    className={styles.button}
                    allowDisabledFocus={true}
                    text="Spreadsheet Verificator"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.props.history.push('/spreadsheet-verificator')}
                  />
                  <PrimaryButton
                    className={styles.button}
                    allowDisabledFocus={true}
                    text="Spreadsheet Verificator & sample data"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={this.createVerificatorSample}
                  />
                </Stack>
              </Stack>
            </Stack.Item>
            <Stack.Item order={orders[2]} className={styles.addinItem}>
              <Stack tokens={tokens.sectionStack}>
                <Stack tokens={tokens.headingStack}>
                  <Text className={styles.titleText} variant={titleFontSize}>
                    Bottleneck Detector
                  </Text>
                  <Text variant={contentFontSize} block={true}>
                    Detect the bottlenecks that slow down your workbook.
                  </Text>
                </Stack>
                <Stack tokens={tokens.itemStack}>
                  <PrimaryButton
                    className={styles.button}
                    allowDisabledFocus={true}
                    text="Bottleneck Detector"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.props.history.push('/bottleneck-detector')}
                  />
                </Stack>
              </Stack>
            </Stack.Item>
            <Stack.Item order={orders[3]} className={styles.addinItem}>
              <Stack tokens={tokens.sectionStack}>
                <Stack tokens={tokens.headingStack}>
                  <Text className={styles.titleText} variant={titleFontSize}>
                    Spreadsheet Optimizer
                  </Text>
                  <Text variant={contentFontSize} block={true}>
                    Optimize formulas in your spreadsheets.
                  </Text>
                </Stack>
                <Stack tokens={tokens.itemStack}>
                  <PrimaryButton
                    className={styles.button}
                    allowDisabledFocus={true}
                    text="Spreadsheet Optimizer"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.props.history.push('/spreadsheet-optimizer')}
                  />
                  <PrimaryButton
                    className={styles.button}
                    allowDisabledFocus={true}
                    text="Spreadsheet Optimizer & sample data"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={this.createOptimizerSample}
                  />
                </Stack>
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
      </>
    );
  }
}

export default withRouter(Welcome);

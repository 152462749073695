import React, { lazy, ChangeEvent, useEffect } from 'react';
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { CommandsRegistry } from 'monaco-editor/esm/vs/platform/commands/common/commands';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
// import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { PrimaryButton } from '@fluentui/react';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import ResizableMonacoEditor from '../EditorLSP/editorLSP';
import { getOfficeHost, OfficeHost } from '../../utils';
import materialTheme from './theme';
import DiscreteSlider from '../DiscreteSlider';
import { isUndefined } from 'util';
import style from './index.module.css';
import * as kpiServices from '../../services/kpi';
import { State as ReduxState } from '../../store/reducer';
import { connect } from 'dva';
import selectors from '../../selectors';
import { ISpreadsheetCommunicator } from '../../communicators/abstract-spreadsheet-communicator';
import { SpreadsheetCommunicatorFactory } from '../../communicators/spreadsheet-communicator.factory';
import { findRenderedComponentWithType } from 'react-dom/test-utils';
import Menu from '../ScriptLab/Menu';
// const ResizableMonacoEditor = lazy(() => import('../FormulaEditorWeb/EditorLSP/editorLSP'));

import "handsontable/dist/handsontable.full.css";
import { HotTable } from '@handsontable/react';

import './style.css';
import { integer } from '@codingame/monaco-languageclient';

const fontSizeOptions: IDropdownOption[] = [8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24].map(
  i => ({ key: i, text: "Font Size: " + i.toString() }),
);

const monacoOptions: monacoEditor.editor.IEditorConstructionOptions = {
  lineNumbers: 'off',
  glyphMargin: false, // left side,
  lineDecorationsWidth: 0, // width between line number and content,
  // autoIndent : "none", // no indent guide lines // choice: "none" | "advanced" | "full" | "brackets" | "keep"
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  guides: { indentation: false },
};

const sliderValues = [30, 40, 50, 60, 70, 80, 100, 160];

const sliderMasks = sliderValues.map(i => ({
  value: i,
  label: i.toString(),
}));

const StyledSlider = withStyles({
  root: { margin: '0 12px 0 8px' },
})(DiscreteSlider);

interface IFomulaEditorProps {
  placeholder: string;
  app: string;
  uid: string
}

interface IFomulaEditorState {
  code: string;
  enabled: boolean;
  widthLimit: number;
  autoWidthLimit: boolean;
  fontSize: number;
  waiting: boolean;
  screenHeight?: number;
  screenWidth?: number;
  unparsable: boolean; /* will be replaced by parsable */
  parsable: boolean;
  formulaStyle: string;
  numberDecimalSeparator: string;
  displayLanguage: string;
  contentLanguage: string;
  rawData: any;
  showConsole: boolean;
  consoleRef: any;
  resizing: boolean;
  consoleAreaHeight: any;
}

class FormulaEditorAddin extends React.Component<IFomulaEditorProps, IFomulaEditorState> {
  editor: any;
  formatAction: any;
  timeout: any;

  static defaultProps = {
    placeholder: 'Toggle above to activate,\nthen select cells...' ,
  };

  listened: boolean = false;
  communicator?: ISpreadsheetCommunicator;
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      enabled: false,
      autoWidthLimit: true,
      widthLimit: 80,
      fontSize: 16,
      waiting: false,
      unparsable: false,
      parsable: false,
      formulaStyle: "A1",
      numberDecimalSeparator: "None",
      displayLanguage: "None",
      contentLanguage: "None",
      rawData: [
        [1, "#ff6900", "#fcb900"],
        [2, "#fcb900", "#7bdcb5"],
        [3, "#7bdcb5", "#8ed1fc"],
        [4, "#00d084", "#0693e3"],
        [5, "#eb144c", "#abb8c3"]
      ],
      showConsole: false,
      consoleRef: React.createRef(),
      resizing: false,
      consoleAreaHeight: 100,
    };

    try {
      this.communicator = SpreadsheetCommunicatorFactory.createContextCommunicator();
    } catch (ex) {
    }
  }

  writeShortcutKey() {
    console.log("writeShortcutKey");
    this.onClickUnformatAndWrite(); 
  }

  // testShortcutKey() {
  //   if (!this.state.showConsole) { this.setState({showConsole: true}) } 
  //   console.log("testShortcutKey")
  //   this.evaluateFormula(this.editor.getModel().getValueInRange(this.editor.getSelection()));
  // }

  handleEditorDidMount(editor, monaco) {
    // in Excel: F9
    // editor._standaloneKeybindingService.addDynamicKeybinding('testShortcutKey' ,  monaco.KeyMod.Alt | monaco.KeyMod.Shift  | monaco.KeyCode.KEY_T  , this.testShortcutKey.bind(this));

    // const id = "editor.action.quickFix"
    // const newKeyBinding = monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyP
    // const { handler, when } = CommandsRegistry.getCommand(id) ?? {}
    // editor._standaloneKeybindingService.addDynamicKeybinding(id, newKeyBinding, handler)
    // see https://stackoverflow.com/questions/71734607/call-quickfix-by-keyboard-shortcuts

    //@ts-ignore
    editor._standaloneKeybindingService.addDynamicKeybinding('write-command' ,  monaco.KeyMod.Alt | monaco.KeyMod.Shift  | monaco.KeyCode.KeyS  , this.writeShortcutKey.bind(this)); 

    this.editor = editor;
    this.formatAction = this.editor.getAction('editor.action.formatDocument');

    if (this.state.code !== "") {
      this.runFormat(); // I'm not very sure this is the best way. At least, if we don't have this line, https://...formula=xxx will not be auto-formatted after loading.
    }
  }

  runFormat() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log(`this.state.widthLimit in runFormat: ${this.state.widthLimit}`);
      console.log(`this.state.autoWidthLimit in runFormat: ${this.state.autoWidthLimit}`);
      this.formatAction.run().then(() => {
        this.editor.revealPosition({ lineNumber: 1, column: 1 });
        this.editor.setPosition({ lineNumber: 1, column: 1 })
      });
    }, 500);
  }

  componentDidMount() {
    // this.listenCells();
    this.muteCells() // because the default state is OFF after loading

    this.communicator?.read_numberDecimalSeparator().then((x: string) => {
      this.setState({ numberDecimalSeparator: x})
    }).catch(error => { console.log('Error: ' + error) });

    // Not sure if componentDidMount is the best place to put resize listener; somebody suggests useEffect
    this.setState({ screenHeight: window.innerHeight });
    this.setState({ screenWidth: window.innerWidth });
    window.addEventListener('resize', () => {
      console.log("resize")
      this.setState({ screenHeight: window.innerHeight });
      this.setState({ screenWidth: window.innerWidth });
    });
  }

  editorWillMount = (monaco: typeof monacoEditor) => {
    monaco.editor.defineTheme('vs-grey', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: { 'editor.background': '#eaeaea' },
    });
  };

  listenCells = () => {
    if (this.listened) return;
    if (!this.communicator) return;
    this.communicator.addOnSelectionChangeHandler(this.onCellChange.bind(this));
    this.listened = true;
  };
  muteCells = () => {
    if (!this.listened) return;
    if (!this.communicator) return;
    this.communicator.removeSelectionChangeHandler();
    this.listened = false;
  }

  evaluateFormula = (formula: string, formulaFull: string, startP: integer) => {
    this.setState({ waiting: true });
    return this.communicator?.evaluate_formula(formula, formulaFull, startP, this.state.formulaStyle)
      .then(x => {
        this.setState({ rawData: x})
        console.log(x);
        this.setState({ waiting: false })
        return x
      })
      .catch(error => {
        console.log('Error: ' + error);
        this.setState({ waiting: false });
      })
  }

  setParsable = (b: boolean) => {
    // console.log("FormulaEditorAddin, setParsable", b);
    this.setState ({ parsable: this.state.enabled && b })
  }
  getEnabled = () => {
    return this.state.enabled
  }

  readFormula = () => {
    this.setState({ waiting: true });
    if (this.state.enabled) {
      this.communicator?.read_formula(this.state.formulaStyle)
        .then((x: string) => {
          // tslint:disable-next-line: no-console
          if (typeof x == 'string' && x.substring(0, 1) == '=') {
            this.code = x // this.setState({ code: x }) works too // new wrt PrettyFormula
            if (!x.includes('\n')) { // don't auto-format formulas with newlines
              this.format(x);
            } else {
              this.editor.revealPosition({ lineNumber: 1, column: 1 });
              this.editor.setPosition({ lineNumber: 1, column: 1 })
            }
          } else {
            this.code = x.toString();
          }
          this.setState({ waiting: false });
          // tslint:disable-next-line: no-console
          console.log('done');
        })
        .catch(error => {
          this.setState({ waiting: false });
          // tslint:disable-next-line: no-console
          console.log('Error: ' + error);
        });
    }
  };

  onCellChange = () => {
    this.readFormula();
  };

  set code(newValue: string) {
    this.setState({ code: newValue });
  }

  get code(): string {
    if (this.state.enabled) {
      return this.state.code;
    } else {
      return this.props.placeholder;
    }
  }

  get activateStatusText(): string {
    return this.state.enabled ? 'On' : 'Off';
  }

  set widthLimit(value: number | undefined) {
    if (value !== undefined) {
      const oldValue = this.state.widthLimit;
      if (oldValue !== value) {
        this.setState({ widthLimit: value }, this.format);
      }
    }
  }

  get widthLimit(): number | undefined {
    if (this.state.autoWidthLimit) {
      return undefined;
    }
    return this.state.widthLimit;
  }

  unformatFormula(formula: string): string {
    formula = formula.trimLeft();
    if (formula[0] === '=') { // it is a formula rather than a value
      return '= ' + unformatFormulaV4(formula.slice(1));
    } else { // it is just a value rather than a formula
      return formula;
    }
  }

  format = (formula?: string) => {
    if (this.state.enabled) {
      formula = formula ? formula : this.state.code;
      this.runFormat()
    }
  };

  writeFormula = (formula: string) => {
    this.setState({ waiting: true });

    this.communicator?.write_formula(formula, this.state.formulaStyle)
      .then(() => {
        this.setState({ waiting: false });
        // tslint:disable-next-line: no-console
        console.log('done');
      })
      .catch(error => {
        this.setState({ waiting: false });
        // tslint:disable-next-line: no-console
        console.log('Error: ' + error);
      });
  };

  onToggleActivate = (_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if ((checked === undefined) || (checked === false)) {
      this.muteCells()
      this.setState({ enabled: false });      
      this.code = '' 
      this.setState({ parsable: false })
    } else {
      this.listenCells();
      this.setState({ enabled: true }, this.readFormula);
    }
  };

  onClickAutoWidthLimit = (
    _ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean,
  ) => {
    this.setState({ autoWidthLimit: checked === undefined ? true : checked }, this.format);
  };

  onClickCheckbox = (
    _ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean,
  ) => {
    if (checked === undefined || !checked)
      this.setState({ formulaStyle: "A1" }, this.readFormula);
    else
      this.setState({ formulaStyle: "R1C1" }, this.readFormula);
  };

  onClickUnformatAndWrite = () => {
    if (this.state.enabled) {
      try {
        let formula = this.unformatFormula(this.state.code);
        this.writeFormula(formula);
      } catch (e) {
        // couldn't parse the formula 
        this.setState({ unparsable: true });
      }
    }
  };

  onWidthLimitChange = (_ev: ChangeEvent<{}>, value: number | number[]) => {
    if (typeof value === 'number') {
      this.widthLimit = value;
    } else {
      this.widthLimit = value[0];
    }
  };

  onCodeChange = (newCode: string) => {
    this.setState({ unparsable: false });
    if (this.state.enabled) {
      this.code = newCode;
    }
  };

  onFontSizeChange = (_ev: object, option: IDropdownOption | undefined) => {
    if (!isUndefined(option)) {
      if (typeof option.key === 'number') {
        this.setState({ fontSize: option.key });
      }
    }
  };

  mouseMoveHandler = (e) => {
    e.preventDefault();
    if (this.state.resizing) {
      const delta = this.state.consoleRef.current.getBoundingClientRect().top - e.clientY;
      this.setState({ consoleAreaHeight: this.state.consoleAreaHeight + delta })
    }
  };

  isInGoogle() {
    return SpreadsheetCommunicatorFactory.isInGoogle();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.enabled) {
      return true
    } else {
      let compareValues = ['screenHeight', 'screenWidth'];
      return compareValues.map(key => this.state[key] !== nextState[key]).filter(e => e).length != 0;
    }
  }

  render() {

    console.log("userAgent", navigator.userAgent.indexOf("Trident"));
    if (navigator.userAgent.indexOf("Trident") !== -1) {
      // https://github.com/OfficeDev/office-js/issues/2463#issuecomment-1082662125
      // IE is the browser. So here, do one of the following: 
      return (
        <div>This add-in won't run in your version of Office. 
          Please upgrade to either one-time purchase Office 2021 or to a Microsoft 365 account.

          See <a href="https://www.10studio.tech/docs/ExcelVersions" target="_blank">https://www.10studio.tech/docs/ExcelVersions</a>
        </div>
      )
    }

    console.log("this.props.app: ", this.props.app)
    console.log("this.props.uid: ", this.props.uid);
    
    const code = this.code;
    console.log("this.code: ", this.code);
    console.log("this.state.code: ", this.state.code);
    const monacoTheme = this.state.enabled ? 'vs' : 'vs-grey';
    const finalMonacoOptions = {
      ...monacoOptions,
      readOnly: !this.state.enabled,
      fontSize: this.state.fontSize,
    };
    const widthLimit = this.state.widthLimit;
    const hGap = 5;

    const vGap = 5
    let editorVh;
    if (this.state.screenHeight == undefined || this.state.screenHeight == null)
      editorVh = 60
    else if (this.state.screenWidth != undefined && this.state.screenWidth < 600) {
      if ((this.props.app == 'pretty-formula') || (this.props.app == '10studio')) {
        editorVh = (this.state.screenHeight - vGap * 49 - vGap * 8) / this.state.screenHeight * 100
      } else {
        editorVh = (this.state.screenHeight - vGap * 49) / this.state.screenHeight * 100
      }
    }
    else {
      if ((this.props.app == 'pretty-formula') || (this.props.app == '10studio')) {
        editorVh = (this.state.screenHeight - vGap * 35 - vGap * 8) / this.state.screenHeight * 100
     } else {
        editorVh = (this.state.screenHeight - vGap * 35) / this.state.screenHeight * 100
      }
    }
    console.log("size:");
    console.log("this.state.screenWidth",this.state.screenWidth)
    console.log(this.state.screenHeight);
    console.log(this.state.screenWidth);

    // const editorVh = 55;
    // const contentTotalHeight = 40 + 31 + 2 * 32 + 31 + 31 + 24 + 31 + 32 + 20; // Header + Toggle + 2XButton + Tittle + Toggle + Slider + Tittle + Drapdown + 20
    // console.log(contentTotalHeight);
    // const screenHeight = this.state.screenHeight;
    // console.log(screenHeight);
    // let vGap = screenHeight
    //   ? ((screenHeight * (100 - editorVh)) / 100 - contentTotalHeight) / 22
    //   : 5;
    // vGap = Math.max(Math.min(vGap, 6), 2);

    console.log("screenHeight: "); console.log(this.state.screenHeight);
    console.log("editorVh: "); console.log(editorVh);
    console.log("vGap:"); console.log(vGap);
    const buttonCommonStyles = {
      margin: `${0 * vGap}px ${hGap}px ${1 * vGap}px ${hGap}px`, // 2 * vGap * 2 * 2 = 8vGap
      flex: '1 0 0px',
    };

    return (
      /* tslint:disable:jsx-no-multiline-js */
      <MuiThemeProvider theme={materialTheme}>
        <div className="content" style={{ minWidth: this.isInGoogle() ? "unset" : "300px", maxWidth: this.isInGoogle() ? "300px" : "unset" }}>
          <Stack
            tokens={{ childrenGap: 2 * vGap } /* 2 * vGap * 2 = 4vGap */}
            styles={{ root: { marginTop: `${2 * vGap}px` } } /* 2vGap */}
          >
            <Toggle
              styles={{ root: { margin: `0 ${hGap}px`, maxWidth: '590px' } }}
              onChange={this.onToggleActivate}
              onText="On" offText="Off"
            />
            <div>
              <div className="formula-editor-wrapper" style={{ margin: `0 ${hGap}px` }}>
                <ResizableMonacoEditor
                  height={`${editorVh}vh`}
                  theme={monacoTheme}
                  value={code}
                  options={finalMonacoOptions}
                  onChange={this.onCodeChange}
                  editorWillMount={this.editorWillMount}
                  editorDidMount={this.handleEditorDidMount.bind(this)}
                  autoWidthLimit={this.state.autoWidthLimit}
                  widthLimit={this.state.widthLimit}
                  optimizer={!SpreadsheetCommunicatorFactory.isInGoogle()}
                  formulaStyle={this.state.formulaStyle}
                  numberDecimalSeparator={this.state.numberDecimalSeparator}
                  displayLanguage={this.communicator?.read_displayLanguage()}
                  contentLanguage={this.communicator?.read_contentLanguage()}
                  evaluateFormula={this.evaluateFormula}
                  setParsable={this.setParsable}
                  getEnabled={this.getEnabled}
                  enabled={this.state.enabled}
                />
              </div>
            </div>
            <div className="content-control" style={{ minWidth: '250px', display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <div
                  className="content-buttons" style	={{ display: 'flex', flexWrap: 'wrap', }}>
                  <div
                    style={{ display: 'inline-flex', flex: '1 0 0px', minWidth: '250px', maxWidth: '300px', }}>
                    <PrimaryButton styles={{ root: { ...buttonCommonStyles, minWidth: '120px', },}} allowDisabledFocus={true} text="Format"
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={() => this.format()} />
                    <PrimaryButton styles={{ root: { ...buttonCommonStyles, minWidth: '120px', pointerEvents: this.state.parsable ? 'auto' : 'none' }, }}
                      disabled={!this.state.parsable} allowDisabledFocus={true} text="Write to Cell" onClick={this.onClickUnformatAndWrite} />
                  </div>
                </div>
                <Stack horizontal={true} wrap={true} styles={{ inner: { margin: 0 } }}>
                  <div
                    style={{ flex: '1 0 0px', minWidth: '250px', maxWidth: '300px', margin: `${3 * vGap}px ${hGap}px ${3 * vGap}px`, // 3vGap
                    }}>
                    {/* { !this.state.unparsable ?
                      <h5 className={style['unparsable-error-message']}> Need a valid formula before writing to cell </h5> : ""} */}
                    <hr className={style.hr} />
                    <Toggle onChange={this.onClickAutoWidthLimit} 
                    checked={this.state.autoWidthLimit}
                    styles={{ root: { minWidth: '300px', margin: `0px 0px -2px`,} }} // https://stackoverflow.com/questions/69303942/dont-let-flex-to-wrap-label-text-of-toggle
                    onText="Width Limit Auto" offText="Width Limit Manual"/>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <StyledSlider
                        defaultValue={widthLimit}
                        value={widthLimit}
                        // tslint:disable:jsx-no-lambda
                        getAriaValueText={_ => widthLimit.toString()}
                        aria-labelledby="discrete-slider-custom"
                        values={sliderValues}
                        disabled={this.state.autoWidthLimit}
                        valueLabelDisplay="off"
                        // @ts-ignore 
                        onChange={this.onWidthLimitChange}
                        marks={sliderMasks}
                      />
                    </div>
                  </div>
                </Stack>
              </div>
              <div>
                <div style={{ flex: '1 0 0px', minWidth: '250px', maxWidth: '300px',
                    margin: `${1 * vGap}px ${hGap}px ${0 * vGap}px`, // 3vGap
                  }}>
                  <Checkbox styles={{ label: { minWidth: '280px' } }} label="R1C1, EN, comma-separated function" onChange={this.onClickCheckbox} checked={this.state.formulaStyle === "R1C1"}/>
                </div>
                <div style={{ flex: '1 0 0px', minWidth: '250px', maxWidth: '300px',
                    margin: `${1 * vGap}px ${hGap}px`, // 3vGap
                  }}>
                  <hr className={style.hr} />
                  <Dropdown
                    // label="Font Size:"
                    options={fontSizeOptions}
                    defaultSelectedKey={this.state.fontSize}
                    styles={{ label: { paddingTop: `${vGap}px`, paddingBottom: `${vGap}px` } }} // 1vGap
                    onChange={this.onFontSizeChange}
                  />
                </div>
              </div>
            </div>
          </Stack>
        </div>
      </MuiThemeProvider>
      /* tslint:enable:jsx-no-multiline-js */
    );
  }
}

export default connect((state: ReduxState) => ({
  app: selectors.app.selectAppName(state),
  uid: selectors.auth.getUid(state)
}))(FormulaEditorAddin);
